import {gql} from '@apollo/client';

import {pickupLocationFragment} from './pickup-location-queries';

export const fulfillmentTimeFragment = gql`
    fragment FulfillmentTimeFragment on fulfillmentTime {
        fulfillmentTimeId
        fulfillmentScheduleId
        preventAlcohol
        windowStart
        windowEnd
        duration
        cutoffTime
        isAvailable
        capacity
    }
`;

export const fulfillmentStoreFragment = gql`
    fragment FulfillmentStoreFragment on fulfillmentLocation {
        canFulfillAlcohol
        fulfillmentLocationId
        fulfillmentStoreId
        locationName
    }
`;

export const searchDeliveryLocations = gql`
    query SearchDeliveryLocations(
        $addressOne: String
        $city: String
        $state: String
        $zip: String!
        $latitude: Float!
        $longitude: Float!
    ) {
        fulfillmentLocations(
            addressOne: $addressOne
            city: $city
            state: $state
            zip: $zip
            latitude: $latitude
            longitude: $longitude
        ) {
            fulfillmentLocationId
            deliveryProviderNames
            fulfillmentStore {
                city
                state
                zip
                name
            }
        }
    }
`;

export const getFulfillmentStore = gql`
    query getFulfillmentStore($customerId: Int!) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            fulfillmentType
            fulfillmentLocation {
                ...FulfillmentStoreFragment
            }
            pickupLocation {
                ...PickupLocationFragment
            }
        }
    }
    ${fulfillmentStoreFragment}
    ${pickupLocationFragment}
`;

export const getFulfillmentTimes = gql`
    query getFulfillmentTimes($fulfillmentLocationId: Int!) {
        fulfillmentTimes(fulfillmentLocationId: $fulfillmentLocationId) {
            ...FulfillmentTimeFragment
        }
    }
    ${fulfillmentTimeFragment}
`;

export const getFulfillmentLocationByFulfillmentLocationId = gql`
    query getFulfillmentLocationByFulfillmentLocationId($fulfillmentLocationId: Int!) {
        fulfillmentLocation(fulfillmentLocationId: $fulfillmentLocationId) {
            fulfillmentLocationId
            fulfillmentStoreLocation {
                locationId
                ecommerceStatus
            }
        }
    }
`;
