import styled from 'styled-components';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';
import {hyveeRedOutline, hyvee, spacing} from '@hy-vee/themes/lib';
import {CaretRightIcon} from '@hy-vee/icons';
import {FC, PropsWithChildren} from 'react';

const DDContainer = styled.dd`
    margin-left: 32px;
    display: flex;
`;

const StyledChevron = styled(CaretRightIcon)`
    margin-left: ${spacing.extraSmall};
`;

interface ISelectConfirmButtonProps {
    isConfirm?: boolean;
    accessibilityLabel?: string;
    accessibilityLabelledBy?: string;
    onClick?: () => void;
    title: string;
}

const SelectConfirmButton: FC<PropsWithChildren<PropsWithChildren<ISelectConfirmButtonProps>>> = ({
    isConfirm,
    accessibilityLabel,
    accessibilityLabelledBy,
    onClick,
    title
}) => (
    <DDContainer role="presentation">
        <LinkButton
            aria-label={accessibilityLabel}
            aria-labelledby={accessibilityLabelledBy}
            block
            onClick={onClick}
            theme={hyveeRedOutline}
            title={title}
        >
            {isConfirm ? 'Confirm' : 'Select'}
            <StyledChevron color={hyvee.primary} size={'small'} />
        </LinkButton>
    </DDContainer>
);

export default SelectConfirmButton;
