import {IAddressSuggestionsAddress, IAddressInput} from 'client/hooks/use-address-validation/use-address-validation';

export interface AddressFormData {
    addressOne: string;
    addressTwo?: string;
    city: string;
    companyName: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    state: {
        label: string; // full name ie Missouri
        value: string; // state abbreviation ie MO
    };
    zip: string;
}

export enum AddressSavedStatus {
    NONE = '',
    SAVED = 'SAVED',
    UPDATED = 'UPDATED',
    SAVED_NOT_ELIGIBLE = 'SAVED_NOT_ELIGIBLE'
}

export type AddressAddedStatus =
    | {status: ''; address: {}}
    | {status: 'SAVED_NOT_ELIGIBLE'; address: {}}
    | {status: 'SAVED'; address: IAddressInput}
    | {status: 'UPDATED'; address: IAddressInput};

export const formatAddressData = ({
    addressOne,
    addressTwo,
    city,
    companyName,
    firstName,
    lastName,
    phoneNumber,
    state,
    zip
}: AddressFormData) => {
    const phoneNumberWithoutDashes = phoneNumber.replace(/-/gu, '');
    const formattedPhoneNumber = `+1${phoneNumberWithoutDashes.slice(-10)}`;

    return {
        addressOne: addressOne.trim(),
        addressTwo: (addressTwo && addressTwo.trim()) || null,
        city: city.trim(),
        companyName: (companyName && companyName.trim()) || null,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        locationType: 'Residence',
        phoneNumber: formattedPhoneNumber,
        state: state.value,
        zip: zip.slice(0, 5)
    };
};

export const getAddressPayloadForSuggestions = (values: AddressFormData) => {
    return {
        addressOne: values.addressOne.trim(),
        addressTwo: (values.addressTwo && values.addressTwo.trim()) || undefined,
        city: values.city,
        state: values.state.value,
        zip: values.zip
    };
};

export const initializeFormAddressData = (): AddressFormData => ({
    addressOne: '',
    addressTwo: '',
    city: '',
    companyName: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    state: {
        label: '',
        value: ''
    },
    zip: ''
});

export const formatStreetAddressLine = (values: AddressFormData | IAddressSuggestionsAddress) => {
    return `${values.addressOne}${values.addressTwo ? `, ${values.addressTwo}` : ''}`;
};

export const formatStateAddressLine = (values: AddressFormData | IAddressSuggestionsAddress) => {
    const stateToDisplay = typeof values.state === 'object' ? values.state.value : values.state;

    return `${values.city}, ${stateToDisplay}, ${values.zip}`;
};
