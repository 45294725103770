import queryString from 'query-string';
import Cookies from 'js-cookie';
import xss from 'xss';

import {CART_PATH} from '../../../enums/rerouting-paths';
import {isFeatureToggleEnabled} from '../../../utils/view-helpers/view-helpers';

export const deleteStoreSelectionCookies = () => {
    Cookies.remove('SelectedStore');
    Cookies.remove('ConsumerReservationModel');
    Cookies.remove('ShowReservationExpirationMessage');
    Cookies.remove('PickupInfo');
};

const changeLocationClicked = (nextProps) => {
    if (nextProps.passcodeFormSuccessfullySubmitted) {
        deleteStoreSelectionCookies();
        const retUrl = xss(queryString.parse(window.location.search).returnUrl);

        if (!isFeatureToggleEnabled('redirectBackToPage')) {
            if (nextProps.cartItems.length === nextProps.missingCartItems.length) {
                window.location.assign(retUrl);
            } else {
                window.location.assign(CART_PATH);
            }
        } else if (retUrl.includes('/shop/checkout/fulfillment.aspx')) {
            window.location.assign(CART_PATH);
        } else {
            window.location.assign(retUrl);
        }
    }
};

export default changeLocationClicked;
