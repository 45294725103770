import {FC, PropsWithChildren} from 'react';
import {Image} from '@hy-vee/web-core';
import {SvgProductImagePlaceholder} from '@hy-vee/icons';
import styled from 'styled-components';

const ProductStyledList = styled.ul`
    margin: auto 0;
    list-style-type: none;
    width: 250px;
    line-height: 1.5;
    margin: 8px;
    font-weight: 700;
    font-size: 14px;
`;

const IneligibleProductCardContainer = styled.li`
    display: flex;
    flex-flow: colum nowrap;
    justify-content: flex-start;
    align-items: stretch;
    column-gap: 5px;
`;

interface IneligibleProductCardProps {
    name: string | null;
    productImage: string | null;
    productPrice: number | null;
    productSize: string | null;
}

export const IneligibleProductCard: FC<PropsWithChildren<PropsWithChildren<IneligibleProductCardProps>>> = ({
    name,
    productImage,
    productPrice,
    productSize
}) => {
    return (
        <IneligibleProductCardContainer>
            <Image
                alt={name}
                css={'align-self: flex-start'}
                fallbackSrc={<SvgProductImagePlaceholder alt="Product image coming soon" height="61px" width="61px" />}
                height="61px"
                src={productImage}
                width=" 61px"
            />
            <ProductStyledList>
                <li css={'font-weight:600'}>{name}</li>
                <li css={'font-weight: 400'}>{productSize}</li>
                <li css={'font-size: 16px'}>${productPrice}</li>
            </ProductStyledList>
        </IneligibleProductCardContainer>
    );
};
