import {ApolloError} from '@apollo/client';
import * as Sentry from '@sentry/browser';

import {env} from 'client/utils/environment';

interface ICaptureSentryOptions {
    component: string;
    error?: ApolloError;
    extra?: object;
    message: string;
}

export const captureSentryError = (options: ICaptureSentryOptions): void => {
    const event = {
        extra: {
            component: options.component,
            error: options.error,
            ...options.extra
        },
        message: options.message,
        tags: {
            path: window.location.pathname
        }
    };

    if (env() !== 'localhost') {
        Sentry.captureEvent(event);
    } else {
        console.log(`SENTRY ERROR - ${options.message}`, event);
    }
};
