import {Text} from '@hy-vee/design-system';
import {FC, PropsWithChildren} from 'react';
import {CheckIcon, ClearIcon} from '@hy-vee/icons';

import styles from './reservation-stepper.module.css';

export interface IInStoreAvailabilityTextProps {
    isStoreUnavailable: boolean;
    isDeliveryOnly?: boolean;
}

export const text = {
    deliveryAvailable: 'Delivery available',
    deliveryOrPickupAvailable: 'Delivery or pickup available',
    deliveryOrPickupUnavailable: 'Delivery or pickup unavailable',
    pickupUnavailable: 'Pickup unavailable',
    shopInStore: 'Shop in store',
    shopInStoreOnly: 'Shop in store only'
} as const;

const InStoreAvailabilityText: FC<PropsWithChildren<PropsWithChildren<IInStoreAvailabilityTextProps>>> = ({
    isDeliveryOnly,
    isStoreUnavailable
}) => {
    const renderText = () => {
        if (isDeliveryOnly) {
            return (
                <>
                    <Text className={styles.greenAvailableText} textStyle={'body3'}>
                        <CheckIcon color={'var(--color-green--30)'} size={'small'} />
                        {text.shopInStore}
                    </Text>
                    <Text className={styles.unavailableText} textStyle={'body3'}>
                        <ClearIcon color={'var(--color-red--30)'} size={'small'} />
                        {text.pickupUnavailable}
                    </Text>
                    <Text className={styles.greenAvailableText} textStyle={'body3'}>
                        <CheckIcon color={'var(--color-green--30)'} size={'small'} />
                        {text.deliveryAvailable}
                    </Text>
                </>
            );
        }

        if (isStoreUnavailable) {
            return (
                <>
                    <Text className={styles.greenAvailableText} textStyle={'body3'}>
                        <CheckIcon color={'var(--color-green--30)'} size={'small'} />
                        {text.shopInStore}
                    </Text>
                    <Text className={styles.unavailableText} textStyle={'body3'}>
                        <ClearIcon color={'var(--color-red--30)'} size={'small'} />
                        {text.deliveryOrPickupUnavailable}
                    </Text>
                </>
            );
        }

        return (
            <>
                <Text className={styles.greenAvailableText} textStyle={'body3'}>
                    <CheckIcon color={'var(--color-green--30)'} size={'small'} />
                    {text.shopInStore}
                </Text>
                <Text className={styles.greenAvailableText} textStyle={'body3'}>
                    <CheckIcon color={'var(--color-green--30)'} size={'small'} />
                    {text.deliveryOrPickupAvailable}
                </Text>
            </>
        );
    };

    return <div className={styles.availableTextContainer}>{renderText()}</div>;
};

export default InStoreAvailabilityText;
