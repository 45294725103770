import styled from 'styled-components';
import {Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState} from 'react';
import {useQuery} from '@apollo/client';
import LoadingSpinner from '@hy-vee/web-core/lib/components/loading-spinner';
import {ILocation, getNearestStoreFromCookie} from '@hy-vee/user-location';

import Drawer from '_client/components/Drawer/Drawer';
import {getCartsForReservationDetailsQuery} from 'client/graphql/queries/cart-queries';
import {
    GetCartsForReservationDetails,
    GetCartsForReservationDetailsVariables,
    GetCartsForReservationDetails_carts_pickupLocation,
    GetCartsForReservationDetails_carts_store
} from 'autogen/GetCartsForReservationDetails';
import {GetActivePickupLocations_pickupLocations} from 'autogen/GetActivePickupLocations';
import {
    getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations,
    getDeliveryAddressesByCustomerId_deliveryAddresses
} from 'autogen/getDeliveryAddressesByCustomerId';
import {BasketContinuityCartInput, FulfillmentType} from 'autogen/globalTypes';
import {useUserDetails} from 'client/context/user-details';
import {useStack} from 'client/hooks/use-stack';
import {useReservationDrawer} from 'client/hooks/use-reservation-drawer';
import {TAddressSuggestionWithDeliveryEligibility} from 'client/hooks/use-address-validation/use-address-validation';

import {BackButton} from './back-button';
import ShoppingPreferences from './shopping-preferences/ShoppingPreferences';
import ChangeStore from './change-store';
import {ChangeAddress} from './change-address/ChangeAddress';
import LocationOpeningSoon from './location-opening-soon/LocationOpeningSoon';
import {BasketContinuity} from './basket-continuity/BasketContinuity';
import {ChooseDeliveryStore} from './choose-delivery-store/ChooseDeliveryStore';
import {AddressAddedStatus, AddressFormData, initializeFormAddressData} from './add-address/AddAddressUtils';
import {AddAddressForm} from './add-address/AddAddressForm';
import {VerifyAddAddress} from './add-address/VerifyAddAddress';

const SpinnerContainer = styled.div`
    padding-top: 2rem;
    display: block;
    height: 70px;
    position: relative;
    width: 70px;
    margin: 0 auto;
`;

export enum ReservationViewType {
    SHOPPING_PREFERENCE = 'shopping-preference',
    CHANGE_ADDRESS = 'change-address',
    ADD_ADDRESS = 'add-address',
    VERIFY_ADDRESS = 'verify-address',
    LOCATION_OPENING_SOON = 'location-opening-soon',
    BASKET_CONTINUITY = 'basket-continuity',
    CHOOSE_DELIVERY_STORE = 'choose-delivery-store',
    SELECT_A_STORE = 'select-a-store',
    SELECT_A_PREFERRED_STORE = 'select-a-preferred-store'
}

export const DrawerTitle = {
    [ReservationViewType.SHOPPING_PREFERENCE]: 'Shopping preference',
    [ReservationViewType.CHANGE_ADDRESS]: 'Change address',
    [ReservationViewType.ADD_ADDRESS]: 'Add address',
    [ReservationViewType.VERIFY_ADDRESS]: 'Confirm address',
    [ReservationViewType.LOCATION_OPENING_SOON]: 'Location opening soon',
    [ReservationViewType.BASKET_CONTINUITY]: 'Confirm changes',
    [ReservationViewType.CHOOSE_DELIVERY_STORE]: 'Choose your store',
    [ReservationViewType.SELECT_A_STORE]: 'Select a store',
    [ReservationViewType.SELECT_A_PREFERRED_STORE]: 'Select a preferred store'
};

interface IReservationDrawerProps {
    activeView: ReservationViewType;
    isAuthenticated: boolean;
    setActiveView: (item: ReservationViewType) => void;
    closeDrawer: () => void;
    toggleScrollToDefault: Dispatch<SetStateAction<boolean>>;
    registerOnBackCallback: (fn: Function) => Function;
    reset: () => void;
}

const getCartsByType = (data: GetCartsForReservationDetails | undefined) => {
    const carts = data?.carts ?? [];
    const activeCart = carts.find((cart) => cart.isActive) ?? null;

    const findLatestCartByType = (type: FulfillmentType) => {
        // Only need this extra predicate for delivery carts given a customer could have deleted an old address.
        // Always returns true for pickup
        const doesCartHaveRequiredFieldsByType = (cart: {deliveryAddress: Object | null}): boolean =>
            type === FulfillmentType.DELIVERY ? Boolean(cart.deliveryAddress) : true;

        return (
            carts.find(
                (cart) => cart.fulfillmentType === type && doesCartHaveRequiredFieldsByType(cart) && !cart.isActive
            ) ?? null
        );
    };

    return {
        activeCart,
        cartId: activeCart?.cartId ?? null,
        latestDeliveryCart: findLatestCartByType(FulfillmentType.DELIVERY),
        latestPickupCart: findLatestCartByType(FulfillmentType.PICKUP)
    };
};

export const ReservationDrawerBody: FC<IReservationDrawerProps> = ({
    activeView,
    isAuthenticated,
    setActiveView,
    closeDrawer,
    toggleScrollToDefault,
    reset
}) => {
    const {customerId} = useUserDetails();

    const {data, loading} = useQuery<GetCartsForReservationDetails, GetCartsForReservationDetailsVariables>(
        getCartsForReservationDetailsQuery,
        {
            errorPolicy: 'all',
            skip: !customerId,
            variables: {
                customerId: customerId || 0
            }
        }
    );

    const [selectedPickupLocation, setSelectedPickupLocation] =
        useState<GetCartsForReservationDetails_carts_pickupLocation | null>(null);
    const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
        useState<getDeliveryAddressesByCustomerId_deliveryAddresses | null>(null);
    const [fulfillmentType, setFulfillmentType] = useState(FulfillmentType.NOT_SELECTED);
    const [selectedStore, setStore] = useState<GetCartsForReservationDetails_carts_store | null>(null);
    const [storeFromCookie, setStoreFromCookie] = useState<ILocation | undefined>();
    const [pendingPickupLocation, setPendingPickupLocation] = useState<GetActivePickupLocations_pickupLocations | null>(
        null
    );
    const [deliveryStores, setDeliveryStores] = useState<
        getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations[] | null
    >(null);
    const [fulfillmentLocationId, setFulfillmentLocationId] = useState<number | null>(null);
    const [userInputAddress, setUserInputAddress] = useState<AddressFormData>(initializeFormAddressData());
    const [isUserInputAddressEligibleForDelivery, setIsUserInputAddressEligibleForDelivery] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [addressSuggestion, setAddressSuggestion] = useState<TAddressSuggestionWithDeliveryEligibility[]>([]);
    const [newAddressAddedStatus, setNewAddressAddedStatus] = useState<AddressAddedStatus>({
        address: {},
        status: ''
    });
    const [alertShown, setAlertShow] = useState<boolean>(false);
    const {activeCart, cartId, latestDeliveryCart, latestPickupCart} = useMemo(() => getCartsByType(data), [data]);
    const {isSelectAPreferredStore, isSelectAPreferredStoreLoading, isOpen} = useReservationDrawer();

    const [basketContinuityInput, setBasketContinuityInput] = useState<BasketContinuityCartInput>(
        {} as BasketContinuityCartInput
    );

    useEffect(() => {
        if (!activeCart) {
            return;
        }

        setSelectedPickupLocation(activeCart.pickupLocation);
        setFulfillmentType(activeCart.fulfillmentType);
        setSelectedDeliveryAddress(activeCart.deliveryAddress as getDeliveryAddressesByCustomerId_deliveryAddresses);
        setStore(activeCart.store);
        setFulfillmentLocationId(activeCart.fulfillmentLocationId);
        setBasketContinuityInput({
            deliveryAddressId: selectedDeliveryAddress?.deliveryAddressId
                ? Number(selectedDeliveryAddress.deliveryAddressId)
                : null,
            fulfillmentLocationId,
            fulfillmentType,
            pickupLocationId: Number(selectedPickupLocation?.pickupLocationId),
            storeId: selectedStore?.storeId ? Number(selectedStore.storeId) : null
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeCart, setSelectedPickupLocation]);

    useEffect(() => {
        if (alertShown)
            setNewAddressAddedStatus({
                address: {},
                status: ''
            });

        if (newAddressAddedStatus.status !== '') {
            setAlertShow(true);
        }

        if (activeView === ReservationViewType.ADD_ADDRESS) {
            setAlertShow(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeView, isOpen]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => reset, []);

    useEffect(() => {
        if (!isAuthenticated) {
            const readStoreFromCookie = getNearestStoreFromCookie();

            readStoreFromCookie && setStoreFromCookie(readStoreFromCookie);
            setActiveView(ReservationViewType.SELECT_A_STORE);

            return;
        }

        if (isSelectAPreferredStore) {
            setActiveView(ReservationViewType.SELECT_A_PREFERRED_STORE);
        } else {
            setActiveView(ReservationViewType.SHOPPING_PREFERENCE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectAPreferredStore]);

    return loading || isSelectAPreferredStoreLoading ? (
        <SpinnerContainer>
            <LoadingSpinner />
        </SpinnerContainer>
    ) : (
        <>
            {activeView === ReservationViewType.SHOPPING_PREFERENCE && (
                <ShoppingPreferences
                    activeCart={activeCart!}
                    closeDrawer={closeDrawer}
                    latestDeliveryCart={latestDeliveryCart}
                    latestPickupCart={latestPickupCart}
                    selectedFulfillmentLocationId={fulfillmentLocationId}
                    selectedFulfillmentType={fulfillmentType}
                    selectedStore={selectedStore}
                    setActiveView={setActiveView}
                    setBasketContinuityInput={setBasketContinuityInput}
                    setDeliveryAddress={setSelectedDeliveryAddress}
                    setFulfillmentLocationId={setFulfillmentLocationId}
                    setFulfillmentType={setFulfillmentType}
                    setStore={setStore}
                />
            )}
            {(activeView === ReservationViewType.SELECT_A_STORE ||
                activeView === ReservationViewType.SELECT_A_PREFERRED_STORE) && (
                <ChangeStore
                    basketContinuityInput={basketContinuityInput}
                    cartId={cartId}
                    closeDrawer={closeDrawer}
                    customerId={customerId}
                    isAuthenticated={isAuthenticated}
                    pickupLocation={selectedPickupLocation}
                    setActiveView={setActiveView}
                    setBasketContinuityInput={setBasketContinuityInput}
                    setDeliveryAddress={setSelectedDeliveryAddress}
                    setFulfillmentLocationId={setFulfillmentLocationId}
                    setFulfillmentType={setFulfillmentType}
                    setPendingPickupLocation={setPendingPickupLocation}
                    setSelectedPickupLocation={setSelectedPickupLocation}
                    setStore={setStore}
                    store={selectedStore}
                    storeFromCookie={storeFromCookie}
                />
            )}
            {activeView === ReservationViewType.CHANGE_ADDRESS && (
                <ChangeAddress
                    basketContinuityInput={basketContinuityInput}
                    cartId={cartId}
                    closeDrawer={closeDrawer}
                    currentDeliveryAddress={selectedDeliveryAddress}
                    newAddressAddedStatus={newAddressAddedStatus}
                    setAddressSuggestion={setAddressSuggestion}
                    setBasketContinuityInput={setBasketContinuityInput}
                    setDeliveryAddress={setSelectedDeliveryAddress}
                    setDeliveryStores={setDeliveryStores}
                    setFulfillmentLocationId={setFulfillmentLocationId}
                    setFulfillmentType={setFulfillmentType}
                    setIsUserInputAddressEligibleForDelivery={setIsUserInputAddressEligibleForDelivery}
                    setIsVerified={setIsVerified}
                    setStore={setStore}
                    setUserInputAddress={setUserInputAddress}
                    setView={setActiveView}
                />
            )}
            {activeView === ReservationViewType.LOCATION_OPENING_SOON && (
                <LocationOpeningSoon
                    basketContinuityInput={basketContinuityInput}
                    cartId={cartId}
                    closeDrawer={closeDrawer}
                    customerId={customerId}
                    pendingPickupLocation={pendingPickupLocation}
                    setActiveView={setActiveView}
                    setBasketContinuityInput={setBasketContinuityInput}
                    setDeliveryAddress={setSelectedDeliveryAddress}
                    setFulfillmentLocationId={setFulfillmentLocationId}
                    setFulfillmentType={setFulfillmentType}
                    setPendingPickupLocation={setPendingPickupLocation}
                    setSelectedPickupLocation={setSelectedPickupLocation}
                    setStore={setStore}
                />
            )}
            {activeView === ReservationViewType.ADD_ADDRESS && (
                <AddAddressForm
                    setActiveView={setActiveView}
                    setAddressSuggestion={setAddressSuggestion}
                    setIsUserInputAddressEligibleForDelivery={setIsUserInputAddressEligibleForDelivery}
                    setIsVerified={setIsVerified}
                    setUserInputAddress={setUserInputAddress}
                    toggleScrollToDefault={toggleScrollToDefault}
                />
            )}
            {activeView === ReservationViewType.VERIFY_ADDRESS && (
                <VerifyAddAddress
                    addressSuggestions={addressSuggestion}
                    isUserInputAddressEligibleForDelivery={isUserInputAddressEligibleForDelivery}
                    isVerified={isVerified}
                    setActiveView={setActiveView}
                    setDeliveryAddress={setSelectedDeliveryAddress}
                    setDeliveryStores={setDeliveryStores}
                    setFulfillmentType={setFulfillmentType}
                    setNewAddressAddedStatus={setNewAddressAddedStatus}
                    userInputAddress={userInputAddress}
                />
            )}
            {activeView === ReservationViewType.BASKET_CONTINUITY && (
                <BasketContinuity
                    basketContinuityInput={basketContinuityInput}
                    cartId={cartId}
                    closeDrawer={closeDrawer}
                    customerId={customerId}
                    pickupLocation={selectedPickupLocation}
                />
            )}
            {activeView === ReservationViewType.CHOOSE_DELIVERY_STORE && (
                <ChooseDeliveryStore
                    basketContinuityInput={basketContinuityInput}
                    cartId={cartId}
                    closeDrawer={closeDrawer}
                    customerId={customerId}
                    fulfillmentLocations={deliveryStores}
                    setActiveView={setActiveView}
                    setBasketContinuityInput={setBasketContinuityInput}
                    setFulfillmentLocationId={setFulfillmentLocationId}
                    setFulfillmentType={setFulfillmentType}
                    setStore={setStore}
                />
            )}
        </>
    );
};

interface IReservationDrawer {
    onCloseCallback?: () => void;
}

const ReservationDrawer: FC<IReservationDrawer> = ({onCloseCallback}) => {
    const {isOpen, setIsOpen} = useReservationDrawer();
    const [scrollToDefault, toggleScrollToDefault] = useState<boolean>(false);
    const {isAuthenticated} = useUserDetails();

    const {
        topItem: activeView,
        push: setActiveView,
        pop,
        reset
    } = useStack<ReservationViewType>(ReservationViewType.SHOPPING_PREFERENCE);
    const [onBackCallback, setOnBackCallback] = useState<Function | null>(null);

    const goBack = () => {
        if (onBackCallback) {
            onBackCallback();
        }

        pop();
    };

    const registerOnBackCallback = useCallback((fn: Function): Function => {
        setOnBackCallback(() => fn);

        return () => {
            setOnBackCallback(null);
        };
    }, []);

    const closeDrawer = () => {
        setIsOpen(false);

        if (onCloseCallback) {
            onCloseCallback();
        }
    };

    return (
        <Drawer
            activeView={activeView}
            bodyFullHeight
            bodyFullWidth
            footerSecondary={activeView === ReservationViewType.CHANGE_ADDRESS}
            navigation={
                activeView === ReservationViewType.SHOPPING_PREFERENCE ||
                DrawerTitle[activeView] === 'Select a preferred store' ||
                !isAuthenticated ? null : (
                    <BackButton onClick={goBack} />
                )
            }
            onClose={closeDrawer}
            open={isOpen}
            scrollToDefault={scrollToDefault}
            title={!isAuthenticated ? 'Select a location' : DrawerTitle[activeView]}
        >
            <ReservationDrawerBody
                activeView={activeView}
                closeDrawer={closeDrawer}
                isAuthenticated={isAuthenticated}
                registerOnBackCallback={registerOnBackCallback}
                reset={reset}
                setActiveView={setActiveView}
                toggleScrollToDefault={toggleScrollToDefault}
            />
        </Drawer>
    );
};

export {ReservationDrawer};
