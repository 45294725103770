import {gql} from '@apollo/client';

export const fetchAndResetClpeCartSavings = gql`
    mutation FetchAndResetClpeCartSavings($cartId: Int!) {
        fetchAndResetClpeCartSavings(cartId: $cartId) {
            clpeCartSavingId
            cartId
            cartItemId
            rewardAmount
            rewardTypeId
            promotionNumber
        }
    }
`;
