import {Dispatch, FC, SetStateAction, useState} from 'react';

import {getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations} from 'autogen/getDeliveryAddressesByCustomerId';
import ReservationStepperStoreSelectionResult from 'client/views/components/reservation-stepper/reservation-stepper-store-selection-result';
import {getCityStateZip} from 'client/utils/view-helpers/address-view-helpers';
import {getBasketContinuitySummaryDrawer} from 'client/services/basket-continuity-service';
import {BasketContinuityCartInput, FulfillmentType} from 'autogen/globalTypes';
import {GetCartsForReservationDetails_carts_store} from 'autogen/GetCartsForReservationDetails';

import {BasketContinuityLoadingSpinner} from '../../components/reservation-drawer-bc-loading';
import {SecondaryText} from '../location-opening-soon/LocationOpeningSoon';
import {ReservationViewType} from '..';

export const chooseDeliveryStoreText = {
    secondaryText: 'Multiple stores deliver to your address' as const
};

export interface IChooseDeliveryStoreProps {
    basketContinuityInput: BasketContinuityCartInput;
    cartId: string | null;
    customerId: number | undefined;
    fulfillmentLocations: getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations[] | null;
    setActiveView: Dispatch<SetStateAction<ReservationViewType>>;
    setBasketContinuityInput: Dispatch<SetStateAction<BasketContinuityCartInput | any>>;
    closeDrawer: () => void;
    setFulfillmentLocationId: Dispatch<SetStateAction<number | null>>;
    setFulfillmentType: Dispatch<SetStateAction<FulfillmentType>>;
    setStore: Dispatch<SetStateAction<GetCartsForReservationDetails_carts_store>>;
}

export const ChooseDeliveryStore: FC<IChooseDeliveryStoreProps> = ({
    basketContinuityInput,
    cartId,
    customerId,
    fulfillmentLocations,
    setActiveView,
    setBasketContinuityInput,
    setFulfillmentLocationId,
    setFulfillmentType,
    closeDrawer,
    setStore
}) => {
    const [isBCLoading, setIsBCLoading] = useState(false);
    const handleClick = async (
        newfulfillmentLocation: getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations
    ) => {
        setFulfillmentLocationId(Number(newfulfillmentLocation.fulfillmentLocationId));
        setStore(newfulfillmentLocation.fulfillmentStore as GetCartsForReservationDetails_carts_store);
        setFulfillmentType(FulfillmentType.DELIVERY);

        setIsBCLoading(true);
        await getBasketContinuitySummaryDrawer({
            ...basketContinuityInput,
            cartId,
            closeDrawer,
            customerId,
            fulfillmentLocationId: Number(newfulfillmentLocation.fulfillmentLocationId),
            fulfillmentType: FulfillmentType.DELIVERY,
            setActiveView,
            setBasketContinuityInput,
            storeId: Number(newfulfillmentLocation.fulfillmentStoreId)
        });
        setIsBCLoading(false);
    };

    if (isBCLoading) {
        return <BasketContinuityLoadingSpinner />;
    }

    return (
        <>
            <SecondaryText css="padding: 0 16px">{chooseDeliveryStoreText.secondaryText}</SecondaryText>
            {(fulfillmentLocations ?? []).map((fulfillmentLocation) => {
                return (
                    <ReservationStepperStoreSelectionResult
                        address={fulfillmentLocation.fulfillmentStore?.address!}
                        cityStateZip={getCityStateZip(fulfillmentLocation.fulfillmentStore)}
                        hideAvailabilityText
                        isDrawer
                        key={fulfillmentLocation.fulfillmentStore?.storeId}
                        name={fulfillmentLocation.fulfillmentStore?.name}
                        onClick={async () => handleClick(fulfillmentLocation)}
                    />
                );
            })}
        </>
    );
};
