import {Children, cloneElement} from 'react';
import Router from 'next/router';
import getConfig from 'next/config';
import queryString from 'query-string';
import {OrderTypes} from '@hy-vee/shared-utils-aisles-online';

import {replaceStateParentUrl} from '../iframe-helpers';
import {AISLES_ONLINE_PAGE_TITLE, HY_VEE_PAGE_TITLE} from '../../enums/page-title';

import {getPickupLocationName} from './address-view-helpers';

const nextConfig = getConfig();

export const sanitizeAndKebab = (string) =>
    string
        ? string
              .replace(/&/gu, 'And')
              .replace(/[^a-zA-Z0-9\s]/gu, '')
              .replace(/\s+/gu, '-')
        : '';

export const addPropsToChildren = (children, additionalProps) =>
    Children.map(children, (child) => {
        return cloneElement(child, additionalProps);
    });

export const isFeatureToggleEnabled = (toggle) => Boolean(nextConfig.publicRuntimeConfig.featureToggles[toggle]);

export const scrollToTop = () => window.scrollTo(0, 0);

export const scrollToTopOfModal = (modal) => modal.scrollIntoView(true);

export const scrollParentToTop = (behavior = 'auto') =>
    window.parent.scrollTo({
        behavior,
        left: 0,
        top: 0
    });

export const getMTODetailsPageUrl = (productId, name, catergoryId) =>
    `/shop/${sanitizeAndKebab(name)}-P${productId}C${catergoryId}.aspx`;

export const getAislesOnlinePageTitle = (title) =>
    title ? `${title} | ${AISLES_ONLINE_PAGE_TITLE}` : AISLES_ONLINE_PAGE_TITLE;
export const getHyVeePageTitle = (title) => (title ? `${title} | ${HY_VEE_PAGE_TITLE}` : HY_VEE_PAGE_TITLE);

export const getAislesOnlineDetailsPageUrl = (id, productName, includeBasePath = true) => {
    return `${includeBasePath ? '/aisles-online' : ''}/p/${id}/${sanitizeAndKebab(productName)}`;
};

export const getDealDetailsPageUrl = (dealId, newDealsPageEnabled) => {
    if (newDealsPageEnabled) {
        return `${nextConfig.publicRuntimeConfig.deals.url}${dealId}`;
    }

    return `/deals/${dealId}`;
};

export const replacePropertyOnLocationSearch = (listOfValues, paramName) => {
    const queryParameters = queryString.parse(location.search);

    queryParameters[paramName] = listOfValues;

    const stringified = queryString.stringify(queryParameters, {
        arrayFormat: 'comma',
        encode: false
    });

    const seachParameters = `?${stringified}`;

    Router.replace(seachParameters);
    replaceStateParentUrl(seachParameters);
};

export const getStore = (cartObjectData) => {
    if (
        cartObjectData &&
        !cartObjectData.loading &&
        cartObjectData.carts?.length &&
        cartObjectData.carts[0].fulfillmentType !== OrderTypes.NOT_SELECTED &&
        cartObjectData.carts[0].fulfillmentLocation &&
        cartObjectData.carts[0].fulfillmentLocation?.fulfillmentStore
    ) {
        const storeName = getPickupLocationName(
            cartObjectData.carts[0].fulfillmentType,
            cartObjectData.carts[0].pickupLocation,
            cartObjectData.carts[0].fulfillmentLocation
        );

        return {
            id: cartObjectData.carts[0].fulfillmentLocation.fulfillmentStore.storeId,
            name: storeName ? storeName : cartObjectData.carts[0].fulfillmentLocation.fulfillmentStore.name
        };
    }

    return undefined;
};

export const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return null;
    }

    const cleanedPhoneNumber = phoneNumber.replace(/\D/gu, '');
    const match = cleanedPhoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/u);

    if (match) {
        const internationalCode = match[1] ? '+1 ' : '';

        return [internationalCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }

    return phoneNumber;
};

export const formatPhoneNumberWithDashes = (phoneNumber) => {
    if (!phoneNumber) {
        return null;
    }

    const cleanedPhoneNumber = phoneNumber.replace(/\D/gu, '');
    const match = cleanedPhoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/u);

    if (match) {
        return `${match[2]}-${match[3]}-${match[4]}`;
    }

    return phoneNumber;
};

export const getStoreName = (store) => store.nickname ?? store.name;

export const getPickupNameHelper = (store, pickupLocation) => pickupLocation?.name || getStoreName(store);

export const getNumberOfItemsTextByTotal = (total) => (total === 1 ? 'item' : 'items');

export const getNumberOfItems = (items) => {
    if (!items) {
        return 0;
    }

    return items.reduce((accumulator, currentItem) => {
        if (currentItem.__typename === 'orderItem') {
            return currentItem.quantity.uom === 'pound' ? accumulator + 1 : accumulator + currentItem.quantity.value;
        } else if (currentItem.__typename === 'mtoOrderItem') {
            return accumulator + currentItem.quantity;
        }

        // __typename === cartItem
        return currentItem.quantityType === 'POUND' ? accumulator + 1 : accumulator + currentItem.quantity;
    }, 0);
};

export const getFormattedMonth = (date) => {
    if (!date) {
        return '';
    }

    if (date === 'May') {
        return date;
    }

    return `${date}.`;
};
