import {useState} from 'react';

type StackType<T> = T[];

interface StackResult<T> {
    topItem: T;
    push: (item: T) => void;
    pop: () => void;
    reset: () => void;
}

export const useStack = <T>(initialItem: T): StackResult<T> => {
    const [stack, setStack] = useState<StackType<T>>([initialItem]);

    const push = (item: T) => {
        setStack((prevStack) => [...prevStack, item]);
    };

    const pop = () => {
        setStack((prevStack) => prevStack.slice(0, -1));
    };

    const reset = () => {
        setStack([stack[0]]);
    };

    return {
        pop,
        push,
        reset,
        topItem: stack[stack.length - 1] || initialItem
    };
};
