import {Dispatch, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import Radio from '@hy-vee/web-core/lib/components/radio';
import {Button, Text} from '@hy-vee/design-system';
import {sizing} from '@hy-vee/themes/lib';
import {CheckIcon, InformationIcon} from '@hy-vee/icons';

import {FulfillmentType} from 'autogen/globalTypes';
import {
    getDeliveryAddressesByCustomerId_deliveryAddresses,
    getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations
} from 'autogen/getDeliveryAddressesByCustomerId';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {ADDRESS_VERIFICATION} from 'client/enums/feature-toggle-names';
import {
    TAddressSuggestionWithDeliveryEligibility,
    useAddressValidation
} from 'client/hooks/use-address-validation/use-address-validation';

import {ReservationViewType} from '..';
import {filterDeliveryFulfillmentLocations} from '../change-address/ChangeAddress';
import styles from '../../components/reservation-stepper/reservation-stepper.module.css';

import {
    AddressAddedStatus,
    AddressFormData,
    AddressSavedStatus,
    formatStateAddressLine,
    formatStreetAddressLine
} from './AddAddressUtils';

export const subHeaderText = 'Please make sure that we have the right formatting for your address';

export const unableToVerifyAddressText = {
    unableToVerify: 'We could not verify your address.',
    unableToVerifyCTA: 'Please add a new address.'
} as const;

export const statusIndicatorText = {
    deliveryAvailable: 'Delivery available',
    deliveryUnavailable: 'Delivery unavailable',
    unverifiedAddress: 'Address is not verified',
    verifiedAddress: 'Address is verified'
} as const;

export type RadioOptions = 'suggested' | 'userInput';

export interface VerifyAddressProps {
    addressSuggestions: TAddressSuggestionWithDeliveryEligibility[];
    userInputAddress: AddressFormData;
    isUserInputAddressEligibleForDelivery: boolean;
    isVerified?: boolean;
    setActiveView: Dispatch<SetStateAction<ReservationViewType>>;
    setFulfillmentType: Dispatch<SetStateAction<String>>;
    setNewAddressAddedStatus: Dispatch<SetStateAction<AddressAddedStatus>>;
    setDeliveryStores: Dispatch<
        SetStateAction<getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations[] | null>
    >;
    setDeliveryAddress: Dispatch<SetStateAction<getDeliveryAddressesByCustomerId_deliveryAddresses>>;
}

interface VerifyAddAddressCardProps {
    userInputAddress: AddressFormData | TAddressSuggestionWithDeliveryEligibility;
    checkedRadio: RadioOptions;
    radioValue: RadioOptions;
    isEligibleForDelivery: boolean;
    isVerified?: boolean;
    setCheckedRadio: Dispatch<SetStateAction<RadioOptions>>;
}

interface IAddressCardContainerFlexProperty {
    cardOrientation?: 'inline' | 'stacked';
}

interface IStatusIndicatorProps {
    text: string;
    error?: boolean;
}

const AddressCardContainer = styled.div<IAddressCardContainerFlexProperty>`
    display: ${({cardOrientation}) => (cardOrientation === 'stacked' ? 'block' : 'flex')};
    border: 1px solid #707070;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem;
`;

const SubHeaderText = styled.p`
    line-height: ${sizing[24]};
    margin: 1rem;
`;

const LabelHeaderText = styled.p`
    font-weight: bold;
    margin-left: 1rem;
`;

const SubmitAddAddressButton = styled(Button)`
    width: 96%;
    margin: 0.5rem;
    position: absolute;
    bottom: 0;
`;

interface IAddressSuggestionsContainer {
    isScrollable: boolean;
}

const AddressSuggestionsContainer = styled.div<IAddressSuggestionsContainer>`
    overflow-y: ${({isScrollable}) => (isScrollable ? 'scroll' : 'auto')};
    height: ${({isScrollable}) => (isScrollable ? '55%' : 'auto')};
`;

const StatusIndicator = ({text, error}: IStatusIndicatorProps) => {
    if (error) {
        return (
            <p>
                <Text className={styles.errorText} textStyle={'body3'}>
                    <InformationIcon className={styles.errorIcon} color={'var(--color-red--30)'} size={'small'} />
                    {text}
                </Text>
            </p>
        );
    }

    return (
        <p>
            <Text className={styles.greenAvailableText} textStyle={'body3'}>
                <CheckIcon color={'var(--color-green--30)'} size={'small'} />
                {text}
            </Text>
        </p>
    );
};

const VerifyAddAddressCard = ({
    userInputAddress,
    checkedRadio,
    setCheckedRadio,
    radioValue,
    isEligibleForDelivery,
    isVerified
}: VerifyAddAddressCardProps) => {
    const {featureEnabled} = useFeatureToggle();
    const isAddressVerificationEnabled = featureEnabled(ADDRESS_VERIFICATION);

    return (
        <div>
            <AddressCardContainer>
                <Radio
                    checked={checkedRadio === radioValue}
                    onChange={() => {
                        setCheckedRadio(radioValue);
                    }}
                    value={radioValue}
                />
                <div css="margin-left: 0.5rem">
                    <p>{formatStreetAddressLine(userInputAddress)}</p>
                    <p>{formatStateAddressLine(userInputAddress)}</p>
                    {isAddressVerificationEnabled &&
                        (isVerified ? (
                            <StatusIndicator text={statusIndicatorText.verifiedAddress} />
                        ) : (
                            <StatusIndicator error text={statusIndicatorText.unverifiedAddress} />
                        ))}
                    {isEligibleForDelivery ? (
                        <StatusIndicator text={statusIndicatorText.deliveryAvailable} />
                    ) : (
                        <StatusIndicator error text={statusIndicatorText.deliveryUnavailable} />
                    )}
                </div>
            </AddressCardContainer>
        </div>
    );
};

const UnableToVerifyAddressCard = () => (
    <AddressCardContainer cardOrientation="stacked">
        <p>{unableToVerifyAddressText.unableToVerify}</p>
        <p>{unableToVerifyAddressText.unableToVerifyCTA}</p>
    </AddressCardContainer>
);

export const VerifyAddAddress = ({
    userInputAddress,
    addressSuggestions,
    isUserInputAddressEligibleForDelivery,
    isVerified,
    setFulfillmentType,
    setActiveView,
    setNewAddressAddedStatus,
    setDeliveryStores,
    setDeliveryAddress
}: VerifyAddressProps) => {
    const {featureEnabled} = useFeatureToggle();
    const isAddressVerificationEnabled = featureEnabled(ADDRESS_VERIFICATION);

    const defaultRadioButton: RadioOptions = isAddressVerificationEnabled ? 'suggested' : 'userInput';

    const [checkedRadio, setCheckedRadio] = useState<RadioOptions>(defaultRadioButton);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const {
        buildDeliveryAddressPayload,
        createDeliveryAddress,
        isNewAddress,
        getDeliveryAddressForUpdate,
        updateDeliveryAddress
    } = useAddressValidation();

    const submitPostAddress = async () => {
        setIsLoading(true);

        if (isNewAddress(userInputAddress)) {
            const payload = buildDeliveryAddressPayload(
                checkedRadio,
                Boolean(isVerified),
                addressSuggestions[0],
                userInputAddress
            );

            const {response, err} = await createDeliveryAddress(payload);

            if (response && response.ok) {
                setNewAddressAddedStatus({
                    address: payload,
                    status: isUserInputAddressEligibleForDelivery
                        ? AddressSavedStatus.SAVED
                        : AddressSavedStatus.SAVED_NOT_ELIGIBLE
                });
                setFulfillmentType(FulfillmentType.DELIVERY);
                setActiveView(ReservationViewType.CHANGE_ADDRESS);
            }

            if (err) {
                if (err.response?.status === 409) {
                    setErrorMessage('This address is already saved');
                } else if (err.response?.status === 404) {
                    // if address is not found in our DB we save it anyways and display alert
                    setNewAddressAddedStatus({
                        address: {},
                        status: AddressSavedStatus.SAVED_NOT_ELIGIBLE
                    });
                    setActiveView(ReservationViewType.CHANGE_ADDRESS);
                } else {
                    setErrorMessage('There was a problem saving your address');
                }
            }
        } else {
            const addressToUpdate = getDeliveryAddressForUpdate(userInputAddress);

            if (addressToUpdate) {
                const {payload, err} = await updateDeliveryAddress(addressToUpdate, isVerified);

                if (err) {
                    setErrorMessage('There was a problem saving your address');

                    return;
                }

                const activeFulfillmentLocations = filterDeliveryFulfillmentLocations(addressToUpdate);

                if (activeFulfillmentLocations && activeFulfillmentLocations.length > 1) {
                    setDeliveryAddress(addressToUpdate);
                    setDeliveryStores(activeFulfillmentLocations);
                    setActiveView(ReservationViewType.CHOOSE_DELIVERY_STORE);

                    return;
                }

                setNewAddressAddedStatus({
                    address: payload,
                    status: isUserInputAddressEligibleForDelivery
                        ? AddressSavedStatus.UPDATED
                        : AddressSavedStatus.SAVED_NOT_ELIGIBLE
                });
                setFulfillmentType(FulfillmentType.DELIVERY);
                setActiveView(ReservationViewType.CHANGE_ADDRESS);
            }
        }

        setIsLoading(false);
    };

    const renderUserInputAddressAndAddressSuggestions = () => {
        if (isAddressVerificationEnabled) {
            return (
                <>
                    <SubHeaderText>{subHeaderText}</SubHeaderText>
                    <LabelHeaderText>What we found</LabelHeaderText>
                    {addressSuggestions.length ? (
                        <AddressSuggestionsContainer isScrollable={addressSuggestions.length >= 3}>
                            {addressSuggestions.map((suggestion) => (
                                <VerifyAddAddressCard
                                    checkedRadio={checkedRadio}
                                    isEligibleForDelivery={suggestion.isEligibleForDelivery}
                                    isVerified
                                    radioValue="suggested"
                                    setCheckedRadio={setCheckedRadio}
                                    userInputAddress={suggestion}
                                />
                            ))}
                        </AddressSuggestionsContainer>
                    ) : (
                        <UnableToVerifyAddressCard />
                    )}
                    <LabelHeaderText>What you entered</LabelHeaderText>
                    <VerifyAddAddressCard
                        checkedRadio={checkedRadio}
                        isEligibleForDelivery={isUserInputAddressEligibleForDelivery}
                        isVerified={isVerified}
                        radioValue="userInput"
                        setCheckedRadio={setCheckedRadio}
                        userInputAddress={userInputAddress}
                    />
                </>
            );
        }

        return (
            <>
                <LabelHeaderText>What you entered</LabelHeaderText>
                <VerifyAddAddressCard
                    checkedRadio={checkedRadio}
                    isEligibleForDelivery={isUserInputAddressEligibleForDelivery}
                    radioValue="userInput"
                    setCheckedRadio={setCheckedRadio}
                    userInputAddress={userInputAddress}
                />
                {addressSuggestions.length > 0 && (
                    <>
                        <LabelHeaderText>What we found</LabelHeaderText>
                        <VerifyAddAddressCard
                            checkedRadio={checkedRadio}
                            isEligibleForDelivery={addressSuggestions[0].isEligibleForDelivery}
                            radioValue="suggested"
                            setCheckedRadio={setCheckedRadio}
                            userInputAddress={addressSuggestions[0]}
                        />
                    </>
                )}
            </>
        );
    };

    return (
        <>
            {renderUserInputAddressAndAddressSuggestions()}
            {errorMessage && (
                <Text className={styles.errorText} css="margin-left: 1rem" textStyle={'body3'}>
                    {errorMessage}
                </Text>
            )}
            <SubmitAddAddressButton disabled={isLoading} isLoading={isLoading} onClick={submitPostAddress}>
                Save and Continue
            </SubmitAddAddressButton>
        </>
    );
};
