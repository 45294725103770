import {gql} from '@apollo/client';

export const pickupLocationFragment = gql`
    fragment PickupLocationFragment on pickupLocation {
        name
        pickupLocationId
        pickupLocationHasLocker
        fulfillmentLocationId
        fulfillmentStoreId
    }
`;

export const getPickupLocationPasscode = gql`
    query GetPickupLocationPasscode($pickupLocationId: Int!) {
        pickupLocation(pickupLocationId: $pickupLocationId) {
            pickupLocationId
            passcode
        }
    }
`;

export const getActivePickupLocationsQuery = gql`
    query GetActivePickupLocations {
        pickupLocations(where: {isActive: true}) {
            pickupLocationId
            pickupStoreId
            name
            address
            city
            state
            zip
            isActive
            latitude
            longitude
            fulfillmentLocationId
            fulfillmentType
            fulfillmentStoreId
            fulfillmentStoreLocation {
                locationId
                ecommerceStatus
                format {
                    code
                }
            }
            fulfillmentStore {
                storeId
                name
                address
                city
                state
                zip
                latitude
                longitude
            }
            hasPasscode
        }
    }
`;
