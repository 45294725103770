import {Paper} from '@material-ui/core';
import styled from 'styled-components';
import {colors} from '@hy-vee/themes';
import {FC, PropsWithChildren} from 'react';

const StyledLocationCard = styled(Paper)`
    border: 1px ${colors.grey[400]} solid;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 19px 15px;
`;

const StyledStoreIconWrapper = styled.div`
    align-items: center;
    background: ${colors.grey[200]};
    border-radius: 50%;
    height: 40px;
    display: flex;
    justify-content: center;
    width: 40px;
`;

interface LocationCardProps {
    icon: JSX.Element;
}

const ShoppingPrefereceLocationCard: FC<PropsWithChildren<LocationCardProps>> = ({children, icon}) => (
    <StyledLocationCard>
        <StyledStoreIconWrapper>{icon}</StyledStoreIconWrapper>
        {children}
    </StyledLocationCard>
);

export default ShoppingPrefereceLocationCard;
