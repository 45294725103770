import {gql} from '@apollo/client';

export const updatePreferredStoreMutation = gql`
    mutation UpdatePreferredStore($preferredStoreId: Int) {
        updatePreferredStore(preferredStoreId: $preferredStoreId) {
            customerId
            preferredStoreId
        }
    }
`;
