import {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useQuery} from '@apollo/client';
import styled from 'styled-components';
import {Alert, Button} from '@hy-vee/web-core';
import {calculateSubtotal} from '@hy-vee/shared-utils-pricing';
import {breakpoints} from '@hy-vee/themes';

import {
    GetBasketContinuitySummary,
    GetBasketContinuitySummaryVariables,
    GetBasketContinuitySummary_getBasketContinuitySummary_ineligibleProducts
} from 'autogen/GetBasketContinuitySummary';
import {getBasketContinuitySummaryQuery} from 'client/graphql/queries/basket-continuity-queries';
import AlertStatusType from 'client/enums/alert-status-types';
import {useLocationId} from 'client/hooks/location-hooks';
import {locationIsIneligible} from 'client/utils/view-helpers/fulfillment-view-helpers';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {BasketContinuityCartInput} from 'autogen/globalTypes';
import {GetCartsForReservationDetails_carts_pickupLocation} from 'autogen/GetCartsForReservationDetails';
import {executeBasketContinuityDrawer} from 'client/services/basket-continuity-service';

import {BasketContinuityLoadingSpinner} from '../../components/reservation-drawer-bc-loading';

import {IneligibleProductCard} from './IneligibleProductCard';

const StyledSubtitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
`;

const StyledList = styled.ul`
    display: block;
    overflow-y: auto;
`;

const StyledBulletParagraph = styled.p`
    line-height: 1.2;
    &:before {
        content: '•';
        font-size: 1.4rem;
        margin-right: 3px;
    }
`;

const Footer = styled.div`
    position: sticky;
    bottom: 0;
    padding: 24px 0;
    z-index: 1;
    background-color: white;

    @media screen and (min-width: ${breakpoints.small}) {
        padding: 24px;
    }
`;

const BasketContinuityReviewContainer = styled.div`
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: start;
`;

export interface IBasketContinuityProps {
    basketContinuityInput: BasketContinuityCartInput;
    cartId: string | null;
    customerId: number | undefined;
    closeDrawer: () => void;
    pickupLocation: GetCartsForReservationDetails_carts_pickupLocation | null;
}

interface IBasketContinuityReviewStepSubTitleProps {
    ineligibleProducts: GetBasketContinuitySummary_getBasketContinuitySummary_ineligibleProducts[];
    isBasketContinuityLoading: boolean;
    isSelectedStoreIsShopInStoreOnly: boolean;
}

const getProductSubTotal = (product: GetBasketContinuitySummary_getBasketContinuitySummary_ineligibleProducts) => {
    if (!product.item || !product.item.retailItems?.length) {
        return null;
    }

    const retailItems = product.item.retailItems[0];
    const isWeighted = retailItems.soldByUnitOfMeasure?.code !== 'CT' && Boolean(product.item.unitAverageWeight);

    return calculateSubtotal(
        retailItems.ecommerceTagPrice!,
        retailItems.ecommerceTagPriceQuantity!,
        retailItems.tagPriceQuantity * (isWeighted ? (product.item.unitAverageWeight as number) : 1)
    );
};

const BasketContinuityReviewStepSubTitle: FC<
    PropsWithChildren<PropsWithChildren<IBasketContinuityReviewStepSubTitleProps>>
> = ({ineligibleProducts, isBasketContinuityLoading, isSelectedStoreIsShopInStoreOnly}) => {
    if (isBasketContinuityLoading) {
        return null;
    }

    const removedItemsLength = ineligibleProducts?.length;

    let weFoundIssuesMessage;
    const subMessage: string[] = [];

    if (isSelectedStoreIsShopInStoreOnly) {
        weFoundIssuesMessage = "We've found 1 issue";
        subMessage.push('This store does not offer delivery or pickup and you will be unable to checkout.');
    }

    if (removedItemsLength) {
        weFoundIssuesMessage = `We've found ${removedItemsLength} issue${removedItemsLength > 1 ? 's' : ''}`;
        subMessage.push(
            `${removedItemsLength} Item${
                removedItemsLength > 1 ? 's' : ''
            } not available and will be removed from your cart if you continue.`
        );
    }

    return (
        <StyledSubtitleContainer>
            <Alert data-testid="alert" status={AlertStatusType.ERROR_ALERT}>
                {weFoundIssuesMessage && <strong>{weFoundIssuesMessage}</strong>}
                {subMessage.map((message) => (
                    <StyledBulletParagraph key={message}>{message}</StyledBulletParagraph>
                ))}
            </Alert>
        </StyledSubtitleContainer>
    );
};

export const BasketContinuity: FC<PropsWithChildren<PropsWithChildren<IBasketContinuityProps>>> = ({
    basketContinuityInput,
    cartId,
    customerId,
    pickupLocation,
    closeDrawer
}) => {
    const [isBCLoading, setIsBCLoading] = useState(false);
    const [products, setProducts] = useState<
        GetBasketContinuitySummary_getBasketContinuitySummary_ineligibleProducts[]
    >([]);
    const {featureEnabled} = useFeatureToggle();
    const {locationIds, locationIdLoading} = useLocationId(basketContinuityInput.storeId?.toString());

    const {data, loading: loadingBCSummary} = useQuery<GetBasketContinuitySummary, GetBasketContinuitySummaryVariables>(
        getBasketContinuitySummaryQuery,
        {
            skip: locationIdLoading,
            variables: {
                cartId: cartId || '',
                input: basketContinuityInput,
                locationId: locationIds[0]
            }
        }
    );

    useEffect(() => {
        if (!data) return;

        setProducts(data.getBasketContinuitySummary?.ineligibleProducts ?? []);
    }, [data]);

    const productsBeingRemovedList = products.map((product) => ({
        name: product.name,
        productId: product.productId,
        productImage: product.productImages?.length ? product.productImages[0].uri : null,
        productPrice: getProductSubTotal(product),
        productSize: product.size
    }));

    const handleClick = async () => {
        setIsBCLoading(true);

        await executeBasketContinuityDrawer({
            ...basketContinuityInput,
            cartId,
            closeDrawer,
            customerId
        });

        setIsBCLoading(false);
    };

    if (isBCLoading || loadingBCSummary || locationIdLoading) {
        return <BasketContinuityLoadingSpinner />;
    }

    return productsBeingRemovedList.length >= 1 ? (
        <BasketContinuityReviewContainer>
            <BasketContinuityReviewStepSubTitle
                ineligibleProducts={products}
                isBasketContinuityLoading={loadingBCSummary}
                isSelectedStoreIsShopInStoreOnly={locationIsIneligible(
                    pickupLocation?.fulfillmentStoreLocation?.ecommerceStatus,
                    featureEnabled
                )}
            />
            <StyledList style={{flexGrow: 2}}>
                {productsBeingRemovedList.map(({productId, name, productImage, productPrice, productSize}) => (
                    <IneligibleProductCard
                        key={productId}
                        name={name}
                        productImage={productImage}
                        productPrice={productPrice}
                        productSize={productSize}
                    />
                ))}
            </StyledList>

            <Footer>
                <Button block onClick={handleClick}>
                    Continue with changes
                </Button>
            </Footer>
        </BasketContinuityReviewContainer>
    ) : null;
};
