import {FC} from 'react';
import styled from 'styled-components';
import {Spinner} from '@hy-vee/web-core';

export const messaging = {
    subtitle: 'This may take up to 10 seconds. Thank you for your patience',
    title: 'Please wait...'
};

const SpinnerContainer = styled.div`
    padding-top: 2rem;
    display: block;
    height: 70px;
    position: relative;
    width: 70px;
    margin: 0 auto;
`;

const MessageContainer = styled.div`
    line-height: 1.25;
    margin: 3rem;
    text-align: center;
`;

export const BasketContinuityLoadingSpinner: FC = () => {
    return (
        <div data-testid="basket-continuity-loading">
            <SpinnerContainer>
                <Spinner />
            </SpinnerContainer>

            <MessageContainer>
                <p css="font-size: 1rem; font-weight: 500;">{messaging.title}</p>
                <p css="font-size: 0.875rem; margin-top: 0.2rem;">{messaging.subtitle}</p>
            </MessageContainer>
        </div>
    );
};
