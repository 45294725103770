import {Dispatch, SetStateAction, useState} from 'react';
import AddressForm from '@hy-vee/web-address-form';
import Alert from '@hy-vee/web-core/lib/components/alert';
import {Button} from '@hy-vee/web-core';
import styled from 'styled-components';

import AlertStatusType from 'client/enums/alert-status-types';
import {
    useAddressValidation,
    TAddressSuggestionWithDeliveryEligibility
} from 'client/hooks/use-address-validation/use-address-validation';

import {ReservationViewType} from '..';

import {AddressFormData} from './AddAddressUtils';

export interface IAddAddressFormProps {
    setUserInputAddress: (addressData: AddressFormData) => void;
    setActiveView: Dispatch<SetStateAction<ReservationViewType>>;
    setIsUserInputAddressEligibleForDelivery: Dispatch<SetStateAction<boolean>>;
    setIsVerified: Dispatch<SetStateAction<boolean>>;
    setAddressSuggestion: Dispatch<SetStateAction<TAddressSuggestionWithDeliveryEligibility[]>>;
    toggleScrollToDefault: Dispatch<SetStateAction<boolean>>;
}

const AddAddressFormContainer = styled.div`
    div[role='alert']:first-child {
        margin-left: 1rem;
    }
`;

export const AddAddressForm = ({
    setActiveView,
    setUserInputAddress,
    setIsUserInputAddressEligibleForDelivery,
    setIsVerified,
    setAddressSuggestion,
    toggleScrollToDefault
}: IAddAddressFormProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const [duplicateAddressError, setDuplicateAddressError] = useState(false);
    const {
        fetchAddressSuggestions,
        isUserInputAddressEligibleForDelivery,
        isAddressVerified,
        getAddressSuggestions,
        isNewAddress
    } = useAddressValidation();

    const submitNewAddressToVerify = async (addressData: AddressFormData) => {
        setIsLoading(true);

        if (isNewAddress(addressData)) {
            const addressSuggestionRes = await fetchAddressSuggestions(addressData);
            const userInputAddressDeliveryEligibility =
                await isUserInputAddressEligibleForDelivery(addressSuggestionRes);
            const suggestions = await getAddressSuggestions(addressSuggestionRes);

            setDuplicateAddressError(false);
            setIsVerified(isAddressVerified(addressSuggestionRes));
            setIsUserInputAddressEligibleForDelivery(userInputAddressDeliveryEligibility);
            setAddressSuggestion(suggestions);
            setUserInputAddress(addressData);
            setActiveView(ReservationViewType.VERIFY_ADDRESS);
        }

        setDuplicateAddressError(true);
        toggleScrollToDefault((prevValue) => !prevValue);
        setIsLoading(false);
    };

    return (
        <AddAddressFormContainer>
            {duplicateAddressError && <Alert status={AlertStatusType.ERROR_ALERT}>This Address is already saved</Alert>}
            <AddressForm
                PrimaryButton={(props) => (
                    <Button {...props} disabled={isLoading} isLoading={isLoading}>
                        {'Save & Continue'}
                    </Button>
                )}
                isDrawerView
                onSubmit={async (values) => submitNewAddressToVerify(values)}
            />
        </AddAddressFormContainer>
    );
};
