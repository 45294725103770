import styled from 'styled-components';
import {H6, P, Spinner} from '@hy-vee/web-core';
import {StoreIcon, LocationIcon} from '@hy-vee/icons';
import {RadioGroup} from '@material-ui/core';
import {useLazyQuery, useQuery} from '@apollo/client';
import {breakpoints, colors} from '@hy-vee/themes';
import {Button, PrivacyMask} from '@hy-vee/design-system';
import {FC, useState, Dispatch, SetStateAction, useEffect, useCallback} from 'react';

import {GetCartsForReservationDetails_carts_store} from 'autogen/GetCartsForReservationDetails';
import {
    getDeliveryAddressesByCustomerId_deliveryAddresses,
    getDeliveryAddressesByCustomerId,
    getDeliveryAddressesByCustomerIdVariables,
    getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations_fulfillmentStore
} from 'autogen/getDeliveryAddressesByCustomerId';
import {BasketContinuityCartInput, FulfillmentType, LocationEcommerceStatus} from 'autogen/globalTypes';
import ShoppingPrefereceLocationCard from '_client/components/ShoppingPreference/ShoppingPreferenceLocationCard';
import {ShoppingPreferenceRadioButton} from '_client/components/ShoppingPreference/ShoppingPreferenceRadioButton';
import {getCurrentCustomerInfo} from 'client/graphql/queries/cart-queries';
import {useUserDetails} from 'client/context/user-details';
import {HYVEE_PLUS} from 'client/enums/membership-types';
import {
    getCurrentCustomerInfo as getCurrentCustomerInfoType,
    getCurrentCustomerInfoVariables
} from 'autogen/getCurrentCustomerInfo';
import {getDeliveryAddressesByCustomerId as getDeliveryAddressesByCustomerIdFetch} from 'client/graphql/queries/delivery-address-queries';
import {getBasketContinuitySummaryDrawer} from 'client/services/basket-continuity-service';

import {BasketContinuityLoadingSpinner} from '../../components/reservation-drawer-bc-loading';
import {ReservationViewType} from '..';

export const PICKUP_HEADER_TEXT = 'Pickup at store';
export const DELIVER_HEADER_TEXT = 'Delivery to your door';

export const radioButtonLabelText = {
    DELIVERY: 'Delivery',
    PICKUP: 'Pickup'
};

export const locationCardCtaText = {
    changeAddress: 'Change address' as const,
    changeStore: 'Change store' as const
};

const ShoppingPreferenceContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 21px;
    margin: 16px;
    height: 100%;
    @media screen and (min-width: ${breakpoints.small}) {
        margin: 24px;
    }
`;

const ShoppingPreferenceRadioGroup = styled(RadioGroup)`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;

const ShoppingPreferenceLocationContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledBoldHeader = styled(H6)`
    font-weight: 700;
    padding: 1.2rem 0 0.2rem 0;
    line-height: 27px;
    size: 18px;
`;

const StyledBoldText = styled(P)`
    color: ${colors.grey[700]};
    font-weight: 600;
    line-height: 20px;
    size: 16px;
    margin-bottom: 0;
`;

const StyledText = styled(P)`
    color: ${colors.grey[500]};
    font-weight: 400;
    line-height: 20px;
    size: 14px;
    margin-bottom: 0;
`;

const StyledButton = styled.button`
    font-weight: 700;
    font-size: 14px;
    color: ${colors.grey[700]};
    margin-top: 10px;
    border-bottom: 1px dashed #242729;
`;

const StyledFooter = styled.div`
    position: sticky;
    bottom: 0;
    padding: 24px 0;
`;

const SpinnerContainer = styled.div`
    height: 40px;
    position: relative;
    width: 40px;
`;

interface IDeliveryAddress {
    deliveryAddressId: string;
    addressOne: string;
    addressTwo: string | null;
    firstName: string;
    lastName: string | null;
    city: string;
    state: string;
    zip: string;
}

interface IStore {
    storeId: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
}

interface IFulfillmentStoreLocation {
    locationId: string;
}

interface IPickupLocation {
    pickupLocationId: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    fulfillmentStoreLocation: IFulfillmentStoreLocation | null;
}

interface ICart {
    cartId: string;
    fulfillmentLocationId: number | null;
    fulfillmentType: FulfillmentType;
    store: IStore | null;
    pickupLocation: IPickupLocation | null;
    deliveryAddress: IDeliveryAddress | null;
}

export interface IShoppingPreferenceProps {
    activeCart: ICart;
    selectedFulfillmentType: FulfillmentType;
    setFulfillmentType: (string: FulfillmentType) => void;
    setActiveView: (string: ReservationViewType) => void;
    latestDeliveryCart?: ICart | null;
    latestPickupCart?: ICart | null;
    closeDrawer: () => void;
    setBasketContinuityInput: Dispatch<SetStateAction<BasketContinuityCartInput | any>>;
    setDeliveryAddress: Dispatch<SetStateAction<getDeliveryAddressesByCustomerId_deliveryAddresses | any>>;
    setFulfillmentLocationId: Dispatch<SetStateAction<number | null>>;
    selectedFulfillmentLocationId: number | null;
    setStore: Dispatch<SetStateAction<GetCartsForReservationDetails_carts_store | any>>;
    selectedStore: GetCartsForReservationDetails_carts_store | null;
}

interface IDeliveryAddressCardProps {
    deliveryAddress: IDeliveryAddress | null | undefined;
    fallbackAddress: IDeliveryAddress | null;
    selectedFulfillmentType: FulfillmentType;
    setActiveView: (string: ReservationViewType) => void;
}

export const hyveePromotionalMessages = {
    FREE_WITH_MEMBERSHIP: 'FREE with H+ membership',
    LOW_AS_NINE_NINETY_FIVE: 'Starting as low as $9.95',
    LOW_AS_ZERO: 'Starting as low as $0.00'
};

const DeliveryAddressCard: FC<IDeliveryAddressCardProps> = ({
    deliveryAddress,
    fallbackAddress,
    selectedFulfillmentType,
    setActiveView
}) => {
    const renderedAddress = deliveryAddress ?? fallbackAddress ?? null;

    if (selectedFulfillmentType !== FulfillmentType.DELIVERY) return null;

    return (
        <ShoppingPrefereceLocationCard icon={<LocationIcon />}>
            {!renderedAddress ? (
                <SpinnerContainer>
                    <Spinner />
                </SpinnerContainer>
            ) : (
                <div>
                    <PrivacyMask>
                        <div>
                            <StyledBoldText>{`${renderedAddress?.firstName} ${renderedAddress?.lastName}`}</StyledBoldText>
                            <StyledText>{renderedAddress?.addressOne}</StyledText>
                            {renderedAddress?.addressTwo && <StyledText>{renderedAddress?.addressTwo}</StyledText>}
                            <StyledText>
                                {renderedAddress?.city}, {renderedAddress?.state} {renderedAddress?.zip}
                            </StyledText>
                        </div>
                    </PrivacyMask>
                    <StyledButton onClick={() => setActiveView(ReservationViewType.CHANGE_ADDRESS)}>
                        {locationCardCtaText.changeAddress}
                    </StyledButton>
                </div>
            )}
        </ShoppingPrefereceLocationCard>
    );
};

const ShoppingPreference: FC<IShoppingPreferenceProps> = ({
    activeCart,
    latestDeliveryCart,
    latestPickupCart,
    selectedFulfillmentType,
    setFulfillmentType,
    setActiveView,
    closeDrawer,
    setBasketContinuityInput,
    setDeliveryAddress,
    setFulfillmentLocationId,
    selectedFulfillmentLocationId,
    setStore,
    selectedStore
}) => {
    const {fulfillmentLocationId, store, cartId, fulfillmentType: activeCartFulfillmentType} = activeCart ?? {};
    const {pickupLocation} =
        activeCartFulfillmentType === FulfillmentType.PICKUP_POINT ? activeCart : latestPickupCart ?? {};
    const {deliveryAddress} =
        activeCartFulfillmentType === FulfillmentType.DELIVERY ? activeCart : latestDeliveryCart ?? {};
    const {customerUuid, customerId} = useUserDetails();
    const [loading, setLoading] = useState(false);
    const [isBCLoading, setIsBCLoading] = useState(false);
    const [fallbackAddress, setFallbackAddress] = useState<IDeliveryAddress | null>(null);
    const [fallbackDeliveryStore, setFallbackDeliveryStore] = useState<
        | GetCartsForReservationDetails_carts_store
        | getDeliveryAddressesByCustomerId_deliveryAddresses_fulfillmentLocations_fulfillmentStore
        | null
    >(null);
    const [fallbackDeliveryFulfillmentLocationId, setFallbackDeliveryFulfillmentLocationId] = useState<number | null>(
        null
    );
    const [fallbackDeliveryAddressId, setFallbackDeliveryAddressId] = useState<number | null>(null);

    const {data} = useQuery<getCurrentCustomerInfoType, getCurrentCustomerInfoVariables>(getCurrentCustomerInfo, {
        skip: !customerUuid,
        variables: {
            customerUuid: customerUuid || ''
        }
    });

    const [getDeliveryAddressesByCustomerIdQuery, {loading: deliveryAddressesLoading, data: fetchedAddresses}] =
        useLazyQuery<getDeliveryAddressesByCustomerId, getDeliveryAddressesByCustomerIdVariables>(
            getDeliveryAddressesByCustomerIdFetch,
            {errorPolicy: 'all'}
        );

    // handle results from lazy delivery addresses query
    const setFallbacksFromDeliveryAddresses = useCallback(() => {
        if (!fetchedAddresses) return;

        let theDeliveryAddress = deliveryAddress,
            theFallbackAddress;

        (fetchedAddresses.deliveryAddresses || []).forEach((address) => {
            address.fulfillmentLocations?.forEach((location) => {
                if (location.fulfillmentStoreLocation?.ecommerceStatus !== LocationEcommerceStatus.ACTIVE) return;
                setFallbackDeliveryAddressId(Number(address.deliveryAddressId));
                setFallbackDeliveryFulfillmentLocationId(Number(location.fulfillmentLocationId));
                setFallbackDeliveryStore(location.fulfillmentStore);

                if (!selectedStore) {
                    setFulfillmentLocationId(Number(location.fulfillmentLocationId));
                    setStore(location.fulfillmentStore);
                }

                theFallbackAddress = address;
                theDeliveryAddress = address;
            });
        });

        setFallbackAddress(theFallbackAddress);

        if (!deliveryAddress && theDeliveryAddress)
            setDeliveryAddress(theDeliveryAddress as getDeliveryAddressesByCustomerId_deliveryAddresses);

        if (!theDeliveryAddress && !theFallbackAddress) {
            setFulfillmentType(activeCartFulfillmentType);
            setActiveView(ReservationViewType.ADD_ADDRESS);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchedAddresses]);

    // handle changes to selectedFulfillmentType
    useEffect(() => {
        if (selectedFulfillmentType === FulfillmentType.NOT_SELECTED || !customerId) return;

        if (!store && !pickupLocation) {
            setActiveView(ReservationViewType.SELECT_A_STORE);
        } else if (selectedFulfillmentType === FulfillmentType.DELIVERY) {
            if (activeCartFulfillmentType === FulfillmentType.DELIVERY) {
                // user switched back to delivery, take active cart
                setStore(store);
                setDeliveryAddress(deliveryAddress);
                setFulfillmentLocationId(Number(fulfillmentLocationId));
            } else {
                // user switched to delivery from pickup, take latest delivery cart
                setStore(latestDeliveryCart?.store || fallbackDeliveryStore);
                setDeliveryAddress(latestDeliveryCart?.deliveryAddress || fallbackAddress);
                setFulfillmentLocationId(
                    Number(latestDeliveryCart?.fulfillmentLocationId || fallbackDeliveryFulfillmentLocationId)
                );

                // if we haven't gotten fallback data yet, do so now
                if (!fetchedAddresses) {
                    getDeliveryAddressesByCustomerIdQuery({variables: {customerId}});
                } else {
                    setFallbacksFromDeliveryAddresses();
                }
            }
        } else {
            if (activeCartFulfillmentType !== FulfillmentType.DELIVERY) {
                // user switched back to pickup or pickup_location, take active cart
                if (activeCartFulfillmentType === FulfillmentType.PICKUP_POINT) {
                    setStore(activeCart.pickupLocation);
                    setFulfillmentLocationId(Number(activeCart?.pickupLocation?.fulfillmentStoreLocation?.locationId));
                } else {
                    setStore(store);
                    setFulfillmentLocationId(Number(fulfillmentLocationId));
                }
            } else if (selectedFulfillmentType === FulfillmentType.PICKUP_POINT) {
                // user switched to pickup from delivery, take latest pickup cart
                setStore(latestPickupCart?.pickupLocation || activeCart.pickupLocation);
                setFulfillmentLocationId(
                    Number(
                        latestPickupCart?.pickupLocation?.fulfillmentStoreLocation?.locationId ||
                            activeCart?.pickupLocation?.fulfillmentStoreLocation?.locationId
                    )
                );
            } else if (latestPickupCart) {
                setStore(latestPickupCart.store);
                setFulfillmentLocationId(Number(latestPickupCart.fulfillmentLocationId));
            } else {
                setDeliveryAddress(null);
                setFallbackDeliveryAddressId(null);
                setFallbackDeliveryFulfillmentLocationId(null);
                setFallbackDeliveryStore(null);
                setActiveView(ReservationViewType.SELECT_A_STORE);
            }

            setDeliveryAddress(null);
            setFallbackDeliveryAddressId(null);
            setFallbackDeliveryFulfillmentLocationId(null);
            setFallbackDeliveryStore(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFulfillmentType, customerId]);

    useEffect(() => {
        if (deliveryAddressesLoading || !fetchedAddresses || !customerId) return;

        setFallbacksFromDeliveryAddresses();
    }, [deliveryAddressesLoading, fetchedAddresses, customerId, setFallbacksFromDeliveryAddresses]);

    let isHyveePlusMember = false;

    if (data?.customer?.customerMemberships) {
        isHyveePlusMember = data.customer.customerMemberships[0]?.membership?.membershipType === HYVEE_PLUS || false;
    }

    const buildBasketContinuityFulfillmentLocationId = () =>
        selectedFulfillmentLocationId || fallbackDeliveryFulfillmentLocationId;

    const buildBasketContinuityStoreId = () => {
        const _storeId = Number(selectedStore?.storeId);

        return Number.isNaN(_storeId) ? Number(fallbackDeliveryStore!.storeId) : _storeId;
    };

    const buildBasketContinuityDeliveryAddressId = () => {
        if (selectedFulfillmentType !== FulfillmentType.DELIVERY) return null;

        return Number(deliveryAddress?.deliveryAddressId) || fallbackDeliveryAddressId || null;
    };

    const buildBasketContinuityPickupLocationId = () => {
        if (selectedFulfillmentType !== FulfillmentType.PICKUP_POINT) return null;

        const id = Number(activeCart?.pickupLocation?.pickupLocationId);

        return Number.isNaN(id) ? null : id;
    };

    const handleSaveButtonClick = async () => {
        setLoading(true);
        setIsBCLoading(true);
        await getBasketContinuitySummaryDrawer({
            cartId,
            closeDrawer,
            customerId,
            deliveryAddressId: buildBasketContinuityDeliveryAddressId(),
            fulfillmentLocationId: buildBasketContinuityFulfillmentLocationId(),
            fulfillmentType: selectedFulfillmentType,
            pickupLocationId: buildBasketContinuityPickupLocationId(),
            setActiveView,
            setBasketContinuityInput,
            storeId: buildBasketContinuityStoreId()
        });

        setIsBCLoading(false);
        setLoading(false);
    };

    if (isBCLoading) {
        return <BasketContinuityLoadingSpinner />;
    }

    return (
        <ShoppingPreferenceContainer>
            <div css="flex-grow: 2">
                <ShoppingPreferenceRadioGroup row>
                    <ShoppingPreferenceRadioButton
                        id="Pickup"
                        isSelected={
                            selectedFulfillmentType === FulfillmentType.PICKUP ||
                            selectedFulfillmentType === FulfillmentType.PICKUP_POINT
                        }
                        onSelect={() =>
                            activeCartFulfillmentType !== FulfillmentType.DELIVERY
                                ? setFulfillmentType(activeCartFulfillmentType)
                                : setFulfillmentType(latestPickupCart?.fulfillmentType || FulfillmentType.PICKUP)
                        }
                    >
                        <>
                            <StyledBoldText>{radioButtonLabelText.PICKUP}</StyledBoldText>
                            <StyledText>
                                {isHyveePlusMember
                                    ? hyveePromotionalMessages.FREE_WITH_MEMBERSHIP
                                    : hyveePromotionalMessages.LOW_AS_ZERO}
                            </StyledText>
                        </>
                    </ShoppingPreferenceRadioButton>
                    <ShoppingPreferenceRadioButton
                        id="Delivery"
                        isSelected={selectedFulfillmentType === FulfillmentType.DELIVERY}
                        onSelect={() => setFulfillmentType(FulfillmentType.DELIVERY)}
                    >
                        <>
                            <StyledBoldText>{radioButtonLabelText.DELIVERY}</StyledBoldText>
                            <StyledText>
                                {isHyveePlusMember
                                    ? hyveePromotionalMessages.LOW_AS_ZERO
                                    : hyveePromotionalMessages.LOW_AS_NINE_NINETY_FIVE}
                            </StyledText>
                        </>
                    </ShoppingPreferenceRadioButton>
                </ShoppingPreferenceRadioGroup>
                <ShoppingPreferenceLocationContainer>
                    <StyledBoldHeader>
                        {selectedFulfillmentType === FulfillmentType.PICKUP ||
                        selectedFulfillmentType === FulfillmentType.PICKUP_POINT
                            ? PICKUP_HEADER_TEXT
                            : DELIVER_HEADER_TEXT}
                    </StyledBoldHeader>
                    {selectedFulfillmentType !== FulfillmentType.DELIVERY && selectedStore && (
                        <ShoppingPrefereceLocationCard icon={<StoreIcon />}>
                            <div>
                                <div>
                                    <StyledBoldText>{selectedStore.name}</StyledBoldText>
                                    <StyledText>{selectedStore.address}</StyledText>
                                    <StyledText>
                                        {selectedStore.city}, {selectedStore.state} {selectedStore.zip}
                                    </StyledText>
                                </div>
                                <StyledButton onClick={() => setActiveView(ReservationViewType.SELECT_A_STORE)}>
                                    {locationCardCtaText.changeStore}
                                </StyledButton>
                            </div>
                        </ShoppingPrefereceLocationCard>
                    )}
                    <DeliveryAddressCard
                        deliveryAddress={deliveryAddress}
                        fallbackAddress={fallbackAddress}
                        selectedFulfillmentType={selectedFulfillmentType}
                        setActiveView={setActiveView}
                    />
                </ShoppingPreferenceLocationContainer>
            </div>
            {selectedFulfillmentType !== activeCartFulfillmentType && (
                <StyledFooter>
                    <Button block isLoading={loading} onClick={handleSaveButtonClick}>
                        Save Changes
                    </Button>
                </StyledFooter>
            )}
        </ShoppingPreferenceContainer>
    );
};

export default ShoppingPreference;
