import {colors, spacing, sizing, green} from '@hy-vee/themes';
import {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';
import {LockIcon} from '@hy-vee/icons';
import {Button, PrivacyMask, SecondaryButton} from '@hy-vee/design-system';

import SelectConfirmButton from '../shared/select-confirm-button';
import {selectedBackgroundColor, StyledSelectionCheckmark} from '../../../styles/style-constants';

import InStoreAvailabilityText from './in-store-availability-text';

export const DrawerButtonText = {
    primaryText: 'Continue' as const,
    secondaryText: 'Select' as const
};

const getMilesDisplay = (milesAway: number | undefined): string =>
    milesAway === 1 ? '1.0 mile away' : `${milesAway} miles away`;

const StoreRow = styled.div<{isDrawer?: boolean; selected?: boolean}>`
    align-items: ${(props) => (props.isDrawer ? 'start' : 'center')};
    border-bottom: solid 1px ${colors.grey[200]};
    background-color: ${({selected}) => (selected ? selectedBackgroundColor : '')};
    cursor: pointer;
    display: flex;
    padding: ${spacing.medium};
    text-align: left;
    &:last-child {
        border-bottom: none;
    }

    :hover {
        background: ${colors.grey[200]};
    }
`;

const StoreDetails = styled.dl`
    line-height: 1.4;
    dt {
        font-weight: 500;
    }
    dd {
        margin-inline-start: 0;
    }
`;

const StoreName = styled.dt`
    display: flex;
    align-items: center;
`;

const LockIconWrapper = styled.span`
    position: relative;
    bottom: 2px;
    left: 2px;
`;

const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

const StoreNameContainer = styled.div`
    display: block;
    width: 100%;
    p {
        margin: 0;
    }
`;

const MilesAway = styled.div`
    font-size: ${sizing[14]};
    margin: var(--spacing--sm) 0;
`;

interface IAccessibilityLabelInfo {
    address?: string;
    cityStateZip?: string;
    name?: string;
    showMiles?: boolean;
    milesAway?: number;
    shopInStoreOnly?: boolean;
}

const getAccessibilityLabel = (props: IAccessibilityLabelInfo) => {
    const storeAvailabilityText = props.shopInStoreOnly
        ? 'Shop in store only. Delivery or pickup unavailable.'
        : 'Shop in store. Delivery or pickup available.';

    if (props.showMiles) {
        return `${props.name} ${getMilesDisplay(props.milesAway)} ${storeAvailabilityText}`;
    } else if (props.name) {
        return `${props.name} ${storeAvailabilityText}`;
    }

    return `${props.address} ${props.cityStateZip} ${storeAvailabilityText}`;
};

interface IReservationStepperStoreSelectionResultProps {
    className?: string;
    selected?: boolean;
    onClick: () => void;
    name?: string;
    hasPasscode?: boolean;
    address: string;
    cityStateZip: string;
    showMiles?: boolean;
    milesAway?: number;
    shopInStoreOnly?: boolean;
    isDeliveryOnly?: boolean;
    isDrawer?: boolean;
    isCurrentAddress?: boolean;
    hideAvailabilityText?: boolean;
}

const ReservationStepperStoreSelectionResult: FC<
    PropsWithChildren<PropsWithChildren<IReservationStepperStoreSelectionResultProps>>
> = ({
    className,
    selected,
    onClick,
    name,
    hasPasscode,
    address,
    cityStateZip,
    showMiles,
    milesAway,
    shopInStoreOnly,
    isDeliveryOnly,
    isDrawer,
    isCurrentAddress,
    hideAvailabilityText
}) => {
    return (
        <StoreRow
            className={className}
            data-testid="storeRow"
            isDrawer={isDrawer}
            onClick={onClick}
            selected={selected}
        >
            <StoreNameContainer>
                <StoreDetails>
                    {isDrawer ? (
                        <dt css="margin-right: 5px" data-testid={'storeName'}>
                            <PrivacyMask>
                                {name}
                                {hasPasscode && (
                                    <LockIconWrapper>
                                        <LockIcon data-testid="lock" size="small" />
                                    </LockIconWrapper>
                                )}
                            </PrivacyMask>
                        </dt>
                    ) : (
                        <StoreName data-testid={'storeName'}>
                            {name}
                            {hasPasscode && <LockIcon data-testid="lock" size="small" />}
                            {selected && name && (
                                <StyledSelectionCheckmark
                                    color={green.mediumGreen}
                                    data-testid="checkmark"
                                    size="small"
                                />
                            )}
                        </StoreName>
                    )}
                    <dd>
                        <FlexRow>
                            <div data-testid="store-selection-result__street-address">
                                {address}
                                {selected && !name && (
                                    <StyledSelectionCheckmark
                                        color={green.mediumGreen}
                                        data-testid="checkmark"
                                        size="small"
                                    />
                                )}
                            </div>
                        </FlexRow>
                        <div>{cityStateZip}</div>
                        {showMiles && <MilesAway>{getMilesDisplay(milesAway)}</MilesAway>}
                        {!hideAvailabilityText && (
                            <InStoreAvailabilityText
                                isDeliveryOnly={isDeliveryOnly}
                                isStoreUnavailable={Boolean(shopInStoreOnly)}
                            />
                        )}
                    </dd>
                </StoreDetails>
            </StoreNameContainer>
            {isDrawer ? (
                isCurrentAddress ? (
                    <Button>{DrawerButtonText.primaryText}</Button>
                ) : (
                    <SecondaryButton>{DrawerButtonText.secondaryText}</SecondaryButton>
                )
            ) : (
                <SelectConfirmButton
                    accessibilityLabel={getAccessibilityLabel({
                        address,
                        cityStateZip,
                        milesAway,
                        name,
                        shopInStoreOnly,
                        showMiles
                    })}
                    isConfirm={selected}
                    title="Select Store"
                />
            )}
        </StoreRow>
    );
};

export default ReservationStepperStoreSelectionResult;
