import {FC} from 'react';
import {CaretLeftIcon} from '@hy-vee/icons';
import {colors} from '@hy-vee/themes';
import {LinkButton} from '@hy-vee/web-core/lib/components/button';

import {StyledIconContainer} from 'client/views/components/store-selection/styles';

export const buttonText = 'Back';

interface IBackButton {
    onClick: () => void;
}

export const BackButton: FC<IBackButton> = ({onClick}) => (
    <LinkButton
        css="text-decoration: 1px dotted underline; font-size: 14px"
        onClick={onClick}
        theme={{
            primary: 'black',
            primaryHover: colors.mediumRed
        }}
    >
        <StyledIconContainer css="padding: 0">
            <CaretLeftIcon color="currentColor" size="small" />
        </StyledIconContainer>
        {buttonText}
    </LinkButton>
);
