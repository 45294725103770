import {QueryResult} from '@apollo/client';

import {getLocationByBusinessCodes} from 'client/graphql/queries/location-queries';
import {graphqlClient} from 'client/graphql/graphql-client';
import {AddressFormData} from 'client/views/reservation-drawer/add-address/AddAddressUtils';
import {getDeliveryAddressesByCustomerId_deliveryAddresses} from 'autogen/getDeliveryAddressesByCustomerId';
import {GetLocationByBusinessCodes} from 'autogen/GetLocationByBusinessCodes';

import {IAddressSuggestionsAddress} from './use-address-validation';

interface IFetchAddressSuggestionsPayload {
    addressOne: string;
    addressTwo: string | undefined;
    city: string;
    state: string;
    zip: string;
}

export const serializeAddressData = (
    address: AddressFormData | getDeliveryAddressesByCustomerId_deliveryAddresses
): IFetchAddressSuggestionsPayload => ({
    addressOne: address.addressOne.trim(),
    addressTwo: (address.addressTwo && address.addressTwo.trim()) || undefined,
    city: address.city,
    state: typeof address.state === 'object' ? address.state.value : address.state,
    zip: address.zip
});

export const fetchFulfillmentStoresEcommerceStatus = async (businessCodes: string[]) => {
    const {data, error}: QueryResult<GetLocationByBusinessCodes> = await graphqlClient().query({
        fetchPolicy: 'no-cache',
        query: getLocationByBusinessCodes,
        variables: {
            businessCodes
        }
    });

    return {
        data,
        error
    };
};

export const formatPhoneNumber = (address: AddressFormData) => {
    const phoneNumberWithoutDashes = address.phoneNumber.replace(/-/gu, '');

    return `+1${phoneNumberWithoutDashes.slice(-10)}`;
};

export const mapAddressSuggestionToAddressFormData = (
    addressSuggestion: IAddressSuggestionsAddress,
    newAddress: AddressFormData
): AddressFormData => ({
    addressOne: addressSuggestion.addressOne.trim(),
    addressTwo: (addressSuggestion.addressTwo && addressSuggestion.addressTwo.trim()) || undefined,
    city: addressSuggestion.city.trim(),
    companyName: (newAddress.companyName && newAddress.companyName.trim()) || '',
    firstName: newAddress.firstName.trim(),
    lastName: newAddress.lastName.trim(),
    phoneNumber: formatPhoneNumber(newAddress),
    state: newAddress.state,
    zip: newAddress.zip.slice(0, 5)
});
