import {ClearIcon} from '@hy-vee/icons';
import {breakpoints, sizing, spacing, colors} from '@hy-vee/themes';
import {Drawer as MuiDrawer, useMediaQuery} from '@material-ui/core';
import {PropsWithChildren, ReactNode, useRef, useEffect} from 'react';
import styled from 'styled-components';

import {ReservationViewType} from '../../../client/views/reservation-drawer';

const HyVeeDrawer = styled(MuiDrawer)`
    .MuiDrawer-paper {
        font-family:
            'SF Pro Text',
            system-ui,
            -apple-system,
            BlinkMacSystemFont,
            'Segoe UI',
            Roboto,
            Oxygen,
            Ubuntu,
            Cantarell,
            'Open Sans',
            'Helvetica Neue',
            sans-serif;
    }

    .MuiDrawer-paperAnchorLeft,
    .MuiDrawer-paperAnchorRight {
        @media screen and (min-width: ${breakpoints.small}) {
            height: 100%;
            width: 400px;
        }
    }

    .MuiDrawer-paperAnchorTop,
    .MuiDrawer-paperAnchorBottom {
        height: 90%;
        width: 100%;

        @media screen and (min-width: ${breakpoints.small}) {
            height: 400px;
            width: 100%;
        }
    }

    .MuiBackdrop-root {
        background: rgba(6, 6, 6, 0.7);
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${spacing.medium};
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 1;
`;

const CloseButton = styled.button`
    display: flex;
    align-self: flex-end;
    background-color: transparent;
    border: none;
`;

const BodyContainer = styled.div<{fullWidth?: boolean; fullHeight?: boolean}>`
    height: ${({fullHeight}) => (fullHeight ? '80%' : 'auto')};
    margin-bottom: ${spacing.large};

    ${({fullWidth}) =>
        !fullWidth &&
        `
        margin: 16px;
        @media screen and (min-width: ${breakpoints.small}) {
            margin: 24px;
        }`}
`;

const Title = styled.h1<{fullWidth?: boolean; fontSize?: 28 | 30}>`
    font-family:
        'SF Pro Display',
        system-ui,
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        Roboto,
        Oxygen,
        Ubuntu,
        Cantarell,
        'Open Sans',
        'Helvetica Neue',
        sans-serif;
    font-size: ${({fontSize}) => sizing[fontSize]};
    font-weight: 700;
    margin: ${({fullWidth}) => (fullWidth ? spacing.medium : '0 0 4px 0')};
    line-height: 115%;
`;

const Footer = styled.footer<{boxShadow: boolean; footerSecondary?: boolean}>`
    position: sticky;
    bottom: 0;
    background-color: ${({footerSecondary}) => (footerSecondary ? colors.grey[100] : 'white')};
    box-shadow: ${({boxShadow}) => (boxShadow ? '0px -2px 23px rgba(0, 0, 0, 0.1)' : 'none')};
    z-index: 1;
    padding: 16px;

    @media screen and (min-width: ${breakpoints.small}) {
        padding: 24px;
    }
`;

const MainContainer = styled.div<{fullHeight?: boolean; hideScroll?: boolean}>`
    overflow-y: ${({hideScroll}) => (hideScroll ? 'hidden' : 'auto')};
    position: relative;
    height: ${({fullHeight}) => (fullHeight ? '100%' : 'auto')};
`;

const NavigationContainer = styled.div`
    flex: 1 0 auto;
`;

interface DrawerProps {
    activeView?: ReservationViewType;
    direction?: 'bottom' | 'left' | 'right' | 'top';
    title: string;
    titleSize?: 28 | 30;
    open: boolean;
    onClose: () => void;
    footer?: ReactNode;
    footerSecondary?: boolean;
    navigation?: ReactNode;
    bodyFullWidth?: boolean;
    bodyFullHeight?: boolean;
    boxShadow?: boolean;
    scrollToDefault?: boolean;
    hideScroll?: boolean;
}

const Drawer = ({
    activeView,
    boxShadow = true,
    direction = 'right',
    title,
    titleSize = 28,
    footer,
    footerSecondary,
    navigation,
    bodyFullWidth,
    bodyFullHeight,
    children,
    open,
    onClose,
    scrollToDefault,
    hideScroll
}: PropsWithChildren<DrawerProps>) => {
    const isNotMobile = useMediaQuery(`(min-width: ${breakpoints.small})`);
    const selectedDirection = isNotMobile ? direction : 'bottom';
    const scrollElementRef = useRef<HTMLDivElement | null>(null);

    const scrollToTop = () => {
        scrollElementRef?.current?.scrollTo(0, 0);
    };

    useEffect(() => {
        scrollToTop();
    }, [activeView, scrollToDefault]);

    return (
        <HyVeeDrawer
            PaperProps={{
                square: isNotMobile
            }}
            anchor={selectedDirection}
            data-direction={selectedDirection}
            onClose={onClose}
            open={open}
        >
            <MainContainer
                data-testid="drawer"
                fullHeight={bodyFullHeight}
                hideScroll={hideScroll}
                ref={scrollElementRef}
            >
                <HeaderContainer>
                    <NavigationContainer>{navigation}</NavigationContainer>
                    <CloseButton onClick={onClose} type="button">
                        <ClearIcon ariaLabel="Close" informative />
                    </CloseButton>
                </HeaderContainer>
                <BodyContainer fullHeight={bodyFullHeight} fullWidth={bodyFullWidth}>
                    <Title fontSize={titleSize} fullWidth={bodyFullWidth}>
                        {title}
                    </Title>
                    {children}
                </BodyContainer>
            </MainContainer>
            {footer && (
                <Footer boxShadow={boxShadow} footerSecondary={footerSecondary}>
                    {footer}
                </Footer>
            )}
        </HyVeeDrawer>
    );
};

export default Drawer;
