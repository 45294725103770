import {gql} from '@apollo/client';

export const getIneligibleProductIdsFromBasketContinuityQuery = gql`
    query GetIneligibleProductIdsFromBasketContinuity($cartId: ID!, $input: BasketContinuityCartInput!) {
        getBasketContinuitySummary(cartId: $cartId, input: $input) {
            ineligibleProductIds
        }
    }
`;

export const getBasketContinuitySummaryQuery = gql`
    query GetBasketContinuitySummary($cartId: ID!, $input: BasketContinuityCartInput!, $locationId: ID!) {
        getBasketContinuitySummary(cartId: $cartId, input: $input) {
            ineligibleProductIds
            ineligibleProducts {
                size
                name
                productImages(where: {isPrimary: true, size: "THUMBNAIL"}) {
                    uri
                }
                item {
                    itemId
                    unitAverageWeight
                    retailItems(locationIds: [$locationId]) {
                        ecommerceTagPrice
                        ecommerceTagPriceQuantity
                        soldByUnitOfMeasure {
                            code
                        }
                        tagPriceQuantity
                    }
                }
                productId
            }
        }
    }
`;
