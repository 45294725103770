import {graphqlClient} from '../../graphql-client';
import {UpdateBasketContinuityMutation} from '../basket-continuity-mutations';
import {
    getActiveCartForReservationButton,
    getActiveCartForReservationStepper,
    getActiveCartForGlobalNavigation
} from '../../queries/cart-queries';
import {getFulfillmentTimes} from '../../queries/fulfillment-location-queries';
import {BasketContinuityCartInput} from '../../../../autogen/globalTypes';

export const updateBasketContinuity = (
    cartId: number,
    customerId: number | null,
    basketContinuityVariables: BasketContinuityCartInput
) =>
    graphqlClient().mutate({
        mutation: UpdateBasketContinuityMutation,
        refetchQueries: [
            {
                query: getActiveCartForGlobalNavigation,
                variables: {
                    customerId
                }
            },
            {
                query: getActiveCartForReservationStepper,
                variables: {
                    activeCartId: cartId
                }
            },
            {
                query: getFulfillmentTimes,
                skip: !basketContinuityVariables.fulfillmentLocationId,
                variables: {
                    fulfillmentLocationId: Number(basketContinuityVariables.fulfillmentLocationId)
                }
            },
            {
                query: getActiveCartForReservationButton,
                skip: !customerId,
                variables: {
                    customerId
                }
            }
        ],
        variables: {
            cartId: `${cartId}`,
            input: basketContinuityVariables
        }
    });
