import {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';

interface Props {
    maxwidth?: string;
}

const StyledFocusedContentContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 100vw;
`;

const StyledFocusedContent = styled.div<Props>`
    max-width: ${(props) => props.maxwidth};
    width: 100%;
`;

const FocusedContent: FC<PropsWithChildren<Props>> = ({children, maxwidth = '1200px'}) => (
    <StyledFocusedContentContainer>
        <StyledFocusedContent maxwidth={maxwidth}>{children}</StyledFocusedContent>
    </StyledFocusedContentContainer>
);

export default FocusedContent;
