import {css} from 'styled-components';
import {CheckCircleIcon} from '@hy-vee/icons';

import {useClassnames} from '../hooks/use-classnames';

import styles from './style-constants.module.css';

export const AO_MAX_MOBILE_WIDTH = 600;
export const AO_MAX_SWIMLANE_WIDTH = 1144;
export const LARGE_AD_CUTOFF = 1066;

export const AO_MIN_WEB_SIZE = AO_MAX_MOBILE_WIDTH + 1;

export const alertText = css`
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    padding: 15px;
    width: 100%;
`;
export const lightBorder = '1px solid #ddd';
export const messageGroup = css`
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 0;
`;
export const transitionDuration = '.35s';
export const sitePadding = '20px';
export const pageContent = css`
    flex: 1 0 auto;
    max-width: 1200px;
    padding: 0 ${sitePadding};
`;

const StyledContainer = ({background, children, fixed = false, ...props}) => {
    const styledContainerClassname = useClassnames(styles, {
        background,
        styledFixedContainer: fixed,
        styledRelativeContainer: !fixed
    });

    return (
        <div {...props} className={styledContainerClassname}>
            {children}
        </div>
    );
};

export const StyledRelativeContainer = ({children, ...props}) => (
    <StyledContainer {...props}>{children}</StyledContainer>
);

export const StyledFixedContainer = ({children, ...props}) => (
    <StyledContainer {...props} fixed>
        {children}
    </StyledContainer>
);

export const visibilityToggleMixin = css`
    ${(props) =>
        props.visible !== true &&
        css`
            display: none;
        `};
`;
export const selectedBackgroundColor = '#EDF1ED';
export const StyledSelectionCheckmark = (props) => (
    <CheckCircleIcon {...props} className={styles.styledSelectionCheckmark} />
);

export const fuelSaverBackgroundColor = '#232325';
