import {gql} from '@apollo/client';

export const getLocationByBusinessCodes = gql`
    query GetLocationByBusinessCodes($businessCodes: [String!]!) {
        locationsByBusinessCodes(businessCodes: $businessCodes) {
            locationId
            ecommerceStatus
        }
    }
`;

export const getLocationEcommerceStatus = gql`
    query GetLocationEcommerceStatus($businessCode: String!) {
        locationsByBusinessCode(businessCode: $businessCode) {
            locationId
            ecommerceStatus
        }
    }
`;

export const getAllActiveLocations = gql`
    query getAllActiveLocations($latitude: Float!, $longitude: Float!, $radius: Int!) {
        searchLocationsNearPoint(
            latitude: $latitude
            longitude: $longitude
            radius: $radius
            locationFormatCodes: [
                "COMBO_STORE"
                "CONVENTIONAL_STORE"
                "MAINSTREET_STORE"
                "DRUG_STORE"
                "FAST_AND_FRESH"
                "DOLLAR_FRESH"
            ]
        ) {
            location {
                businessCode
                locationId
                name
                nickname
                phoneNumber
                address {
                    line1
                    line2
                    city
                    state
                    zip
                }
            }
        }
    }
`;
