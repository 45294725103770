import {fetchAndResetClpeCartSavings} from '../clpe-mutations';
import {graphqlClient} from '../../graphql-client';

export const fetchAndResetClpeCartSavingsAction = (cartId) =>
    graphqlClient().mutate({
        mutation: fetchAndResetClpeCartSavings,
        variables: {
            cartId
        }
    });
