import {bindActionCreators} from 'redux';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {spacing} from '@hy-vee/themes';

import {loadTimeReservationPageData} from '../../action-creators';

const StyledReloadButton = styled.button`
    cursor: pointer;
    margin-left: ${spacing.extraSmall};
    text-decoration: underline;
`;

const reload = () => window.location.reload();

const ReloadDataButton = ({actions, action, parameter}) => (
    <StyledReloadButton
        onClick={() => {
            if (action) {
                if (parameter) {
                    actions[action](parameter);
                } else {
                    actions[action]();
                }
            } else {
                reload();
            }
        }}
        type="button"
    >
        {'Please try again.'}
    </StyledReloadButton>
);

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            loadTimeReservationPageData
        },
        dispatch
    )
});

export default connect(null, mapDispatchToProps)(ReloadDataButton);
