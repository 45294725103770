import {Paper, Radio} from '@material-ui/core';
import styled from 'styled-components';
import {CheckIcon} from '@hy-vee/icons';
import {colors} from '@hy-vee/themes';
import {FC, PropsWithChildren} from 'react';

const StyledPaper = styled(Paper)<{selected: boolean}>`
    border: ${({selected}) => `1px solid ${selected ? colors.grey[400] : colors.grey[300]}`};
    border-radius: 5px;
    cursor: pointer;
    height: 144px;
    padding: 12px 15px;
    position: relative;
    width: 141px;
`;

const StyledInputRadio = styled(Radio)`
    position: fixed;
    opacity: 0;
    pointer-events: none;
`;

const StyledCheckMarkWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 23px;
    width: 23px;
    top: -83px;
    left: 111px;
    background-color: ${colors.mediumGreen};
    border-radius: 50%;
`;

interface ShoppingPreferenceRadioButtonProps {
    isSelected: boolean;
    id: string;
    onSelect: () => void;
}

export const ShoppingPreferenceRadioButton: FC<PropsWithChildren<ShoppingPreferenceRadioButtonProps>> = ({
    children,
    isSelected,
    id,
    onSelect
}) => (
    <div data-testid={`reservation-drawer__input--${id.toLowerCase()}`}>
        <StyledInputRadio
            checked={isSelected}
            // checked icon comes from elsewhere; this is just to satisfy the component's types
            // You can't pass null / false / etc to this -- MUI requires it to be a ReactNode
            // eslint-disable-next-line react/jsx-no-useless-fragment
            checkedIcon={<></>}
            // there is no unchecked icon; this is just to satisfy the component's types
            // You can't pass null / false / etc to this -- MUI requires it to be a ReactNod
            // eslint-disable-next-line react/jsx-no-useless-fragment
            icon={<></>}
            id={id}
            name={id}
            onChange={onSelect}
        />
        <label htmlFor={id}>
            <StyledPaper selected={isSelected}>
                {children}
                {isSelected && (
                    <StyledCheckMarkWrapper>
                        <CheckIcon color="white" data-testid="CheckIcon" size={'small'} />
                    </StyledCheckMarkWrapper>
                )}
            </StyledPaper>
        </label>
    </div>
);
