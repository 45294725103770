import {gql} from '@apollo/client';

export const getDeliveryAddressesByCustomerId = gql`
    query getDeliveryAddressesByCustomerId($customerId: Int!) {
        deliveryAddresses(customerId: $customerId) {
            firstName
            lastName
            deliveryAddressId
            addressOne
            addressTwo
            city
            state
            zip
            isVerified
            phoneNumber
            companyName
            locationType
            fulfillmentLocations {
                fulfillmentStore {
                    name
                    city
                    state
                    zip
                    address
                    storeId
                }
                fulfillmentLocationId
                fulfillmentStoreId
                fulfillmentStoreLocation {
                    locationId
                    ecommerceStatus
                }
            }
        }
    }
`;
