import styled from 'styled-components';
import {colors, sizing, spacing} from '@hy-vee/themes/lib';
import {H5, P} from '@hy-vee/web-core/lib/components/typography';
import {Button} from '@hy-vee/web-core/lib/components/button';
import {CaretRightIcon} from '@hy-vee/icons';

export const StyledButton = styled(Button)`
    background-color: ${colors.grey[100]};
    border: 1px solid ${colors.grey[100]};
    color: ${colors.grey[500]};
    display: flex;
    font-weight: 300;
    justify-content: space-evenly;
    margin: ${spacing.small};
    max-width: 300px;
    outline: ${colors.grey[500]};
    padding: ${spacing.large} 12px;
    text-align: left;

    :hover,
    :focus {
        background-color: ${colors.grey[300]};
        border: 1px solid ${colors.grey[300]};
        border-radius: ${spacing.extraSmall};
        color: ${colors.grey[500]};
    }
`;

export const StyledChevron = styled(CaretRightIcon)`
    flex-shrink: 0;
`;

export const StyledDeliverySelection = styled.div`
    display: flex;
    flex-direction: column;
    margin: ${(props) => !props.isReservationStepper && spacing.medium};

    @media (min-width: 500px) {
        margin: ${(props) => !props.isReservationStepper && `${spacing.medium} ${spacing.large}`};
    }
`;

export const StyledH2 = styled.h2`
    font-size: ${sizing[20]};
    margin-bottom: 0;
`;

export const StyledDescription = styled(P)`
    font-size: 13px;
    margin: 0;
`;

export const StyledFulfillmentSelectionDisplay = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`;

export const StyledIconContainer = styled.span`
    align-self: center;
    display: flex;
    padding: 0 ${spacing.small};
`;

export const StyledLocationsRowContainer = styled.div`
    margin: ${spacing.small} 0;
    width: 100%;
`;

export const StyledNoResultsMessage = styled(P)`
    text-align: left;
`;

export const StyledNoteContainer = styled.div`
    color: ${colors.grey[400]};
    font-size: ${sizing[14]};
    line-height: 1;
    margin-top: ${spacing.extraLarge};
    text-align: left;

    a {
        font-size: ${sizing[14]};
    }
`;

export const StyledSearchLocationsContainer = styled.div`
    min-height: 300px;
`;

export const StyledStoreSelectionContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 432px;
    min-width: 100%;
    h1 {
        font-size: ${sizing[20]};
        font-weight: 500;
    }
`;

export const StyledHeaderSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

export const StyledStoreSelectionModal = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 300px;

    @media (min-width: 500px) {
        margin: ${spacing.large};
    }
`;

export const StyledSubheading = styled(H5)`
    margin: ${spacing.small} 0;
`;

export const StyledTabsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: inherit;
`;

export const StyledModalHeader = styled.h1`
    align-items: center;
    background: ${colors.grey[100]};
    display: flex;
    padding: ${spacing.small} ${spacing.medium};
    text-align: left;
`;

export const StyledModalDescription = styled(P)`
    font-size: ${sizing[14]};
    font-weight: 300;
    margin: ${spacing.extraSmall} 0;
`;

export const StyledButtonContainer = styled.div`
    padding: ${spacing.medium} 0 0;
    width: 100%;

    @media (min-width: 500px) {
        width: 160px;
    }
`;

export const StyledDeliveryDisclaimer = styled.div`
    padding: ${spacing.small} ${spacing.medium} 0;
    max-width: 300px;

    p {
        text-align: center;
    }
`;
